import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Collapse,
  Flex,
  Heading,
  useDisclosure,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { useNavigate } from "raviger";
import { FC } from "react";

type AdminAdditionalEntitiesSummaryProps = {
  questionNumber: string;
  questionText: string;
  entitiesString: string;
  submissionId: number;
  isSubmitted: boolean | null | undefined;
};

const AdminAdditionalEntitiesSummary: FC<
  AdminAdditionalEntitiesSummaryProps
> = (props) => {
  const {
    questionNumber,
    questionText,
    entitiesString,
    submissionId,
    isSubmitted,
  } = props;

  const { isOpen, onToggle } = useDisclosure();
  const navigate = useNavigate();

  const entities: { [k: string]: string }[] = JSON.parse(entitiesString);

  return (
    <Box
      borderBottomStyle="solid"
      borderBottomColor="brand.500"
      borderBottomWidth="1px"
      pb="6"
    >
      <Flex justifyContent="space-between">
        <Heading size="sm" mr="2">
          {questionText}
        </Heading>
        <Button
          size="sm"
          ml="0"
          mr="auto"
          rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          onClick={onToggle}
        >
          {isOpen ? "Close" : "View"}
        </Button>
      </Flex>
      <Collapse in={isOpen}>
        <Wrap spacing="2" maxW="100ch">
          {entities.map((entity, i) => {
            return (
              <WrapItem key={`entity-${i}`}>
                <VStack align="flex-start" p="4">
                  {Object.entries(entity).map((eThing, eThingI) => {
                    const [fieldName, fieldValue] = eThing;
                    return (
                      <Box key={`${eThingI}-${fieldName}`}>
                        <Heading whiteSpace="pre-line" size="sm">
                          {fieldName}
                        </Heading>
                        {fieldValue}
                      </Box>
                    );
                  })}
                </VStack>
              </WrapItem>
            );
          })}
        </Wrap>
      </Collapse>
    </Box>
  );
};

export default AdminAdditionalEntitiesSummary;
