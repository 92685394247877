import { Box, Button, SimpleGrid } from "@chakra-ui/react";
import { RenderProps } from "dayzed";
import { FC } from "react";
const weekdayNamesShort = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const Calendar: FC<RenderProps> = (props) => {
  const { calendars, getDateProps } = props;

  if (calendars.length) {
    return (
      <div style={{ margin: "0 auto", maxWidth: "350px" }}>
        {calendars.map((calendar) => {
          return (
            <SimpleGrid
              key={`${calendar.month}${calendar.year}`}
              columns={7}
              placeItems="center"
              spacingX="1"
              spacingY="0"
            >
              {weekdayNamesShort.map((weekday) => {
                return (
                  <Box key={`${calendar.month}${calendar.year}${weekday}`}>
                    {weekday}
                  </Box>
                );
              })}
              {calendar.weeks.map((week, weekIndex) => {
                return week.map((dateObj, index) => {
                  const key = `${calendar.month}${calendar.year}${weekIndex}${index}`;

                  if (!dateObj) {
                    return <Box key={key} />;
                  }

                  let { date, selected, selectable } = dateObj;

                  return (
                    <Button
                      key={key}
                      variant={selected ? "solid" : "ghost"}
                      {...getDateProps({ dateObj })}
                    >
                      {selectable ? date.getDate() : "X"}
                    </Button>
                  );
                });
              })}
            </SimpleGrid>
          );
        })}
      </div>
    );
  }

  return null;
};

export default Calendar;
