import { Button, Center, Container, Heading, VStack } from "@chakra-ui/react";
import { FC, ReactNode } from "react";
import { HOME_ROUTE } from "../../routes";
import { isAdmin } from "../../util/Auth";
import AdminNavigation from "../AdminNavigation/AdminNavigation";
import Header from "../Header/Header";

const AdminWrapper: FC<{ children: ReactNode }> = (props) => {
  const isUserAdmin = isAdmin();

  if (isUserAdmin) {
    return (
      <>
        <Header navigation={<AdminNavigation />} />
        <Container as="main">{props.children}</Container>
      </>
    );
  } else {
    return (
      <>
        <Header />
        <Center>
          <VStack>
            <Heading>Sorry, that page isn't accessible</Heading>
            <Button
              onClick={() => {
                window.history.back();
              }}
            >
              Go back
            </Button>
            <a href={HOME_ROUTE}>
              <Button>Go to home</Button>
            </a>
          </VStack>
        </Center>
      </>
    );
  }
};

export default AdminWrapper;
