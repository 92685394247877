import jwtDecode from "jwt-decode";
import { navigate } from "raviger";
import { useQueryClient } from "react-query";
import { TokenPayload } from "../../types/tokenPayload";
import { HOME_ROUTE } from "../routes";
import {
  getLocalValue,
  removeLocalValue,
  TOKEN_KEY,
} from "../services/localStorage";

export const getToken = () => {
  const token = getLocalValue<string>(TOKEN_KEY);

  if (token) {
    return token;
  } else {
    return "";
  }
};

const getDecodedToken = () => {
  const token = getLocalValue<string>(TOKEN_KEY);

  if (token) {
    try {
      const decodedToken = jwtDecode<TokenPayload>(`${token}`);

      if (decodedToken) {
        return decodedToken;
      }
    } catch (e) {
      console.error(e);
      return false;
    }
  }
  return false;
};

export const getTokenProperty = (key: keyof TokenPayload) => {
  const token = getDecodedToken();

  if (token && token[key]) {
    return token[key];
  }

  return null;
};

export const isLoggedIn = () => {
  const decodedToken = getDecodedToken();

  if (decodedToken) {
    try {
      const expiresAt = decodedToken.exp;
      const expiryDate = new Date(expiresAt * 1000);

      if (expiryDate > new Date()) {
        return true;
      }
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  return false;
};

export const isAdmin = () => {
  const decodedToken = getDecodedToken();

  if (decodedToken) {
    const role = decodedToken.role;

    if (role === "admin") {
      return true;
    }
  }

  return false;
};

export const logout = () => {
  removeLocalValue(TOKEN_KEY);
  navigate(HOME_ROUTE);
};

export const useLogout = () => {
  const queryClient = useQueryClient();

  return () => {
    queryClient.clear();
    removeLocalValue(TOKEN_KEY);
    navigate(HOME_ROUTE);
  };
};
