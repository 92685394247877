import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useBoolean,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { useNavigate, useQueryParams } from "raviger";
import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { LOGIN_ROUTE } from "../../routes";
import Header from "../Header/Header";

type FormData = { new_password: string; new_password_2: string };

const NewPassword: FC = () => {
  const navigate = useNavigate();
  const [params] = useQueryParams();

  const [showNew, { toggle: toggleNew }] = useBoolean(false);
  const [showNew2, { toggle: toggleNew2 }] = useBoolean(false);

  const toast = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    setError,
    clearErrors,
    watch,
  } = useForm<FormData>({
    defaultValues: {
      new_password: "",
      new_password_2: "",
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      const response = await axios.post(
        "/api/auth/password",
        {
          newPassword: data.new_password,
          token: params.token,
        },
        {
          params: {
            new_password: "yes",
          },
        }
      );

      if (response.status === 200) {
        toast({
          status: "success",
          description: `Password successfully updated.
          Navigating to login.`,
          onCloseComplete: () => {
            navigate(LOGIN_ROUTE);
          },
        });
      } else if (response.status === 403) {
        toast({
          status: "error",
          description: `Update failed. Link expired.
            Please request a new reset`,
        });
      } else {
        toast({
          status: "error",
          description: "Update failed. Please try again.",
        });
      }
    } catch (e) {
      console.error(e);
    }
  });

  const vales = watch(["new_password", "new_password_2"]);

  useEffect(() => {
    if (touchedFields.new_password_2) {
      if (vales[0] !== vales[1]) {
        if (errors.new_password_2?.type === "match") {
        } else {
          setError("new_password_2", {
            message: "New passwords do not match",
            type: "match",
          });
        }
      } else {
        if (errors.new_password_2?.type === "match") {
          clearErrors("new_password_2");
        }
      }
    }
  }, [setError, clearErrors, touchedFields, vales, errors]);

  return (
    <>
      <Header />
      <Center>
        <Box width="100%" maxW="50ch">
          <form onSubmit={onSubmit}>
            <FormControl mb="1em">
              <FormLabel>New password:</FormLabel>
              <InputGroup>
                <Input
                  type={showNew ? "text" : "password"}
                  {...register("new_password", { required: true })}
                />
                <InputRightElement>
                  <Button
                    borderLeftRadius={0}
                    pr="8"
                    pl="8"
                    onClick={toggleNew}
                  >
                    {showNew ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
              {errors.new_password &&
                errors.new_password.type === "required" && (
                  <Text color="red">Please enter new password</Text>
                )}
            </FormControl>
            <FormControl mb="1em">
              <FormLabel>Confirm new password:</FormLabel>
              <InputGroup>
                <Input
                  type={showNew2 ? "text" : "password"}
                  {...register("new_password_2", { required: true })}
                />
                <InputRightElement>
                  <Button
                    borderLeftRadius={0}
                    pr="8"
                    pl="8"
                    onClick={toggleNew2}
                  >
                    {showNew2 ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
              {errors.new_password_2 &&
                errors.new_password_2.type === "required" && (
                  <Text color="red">Please confirm new password</Text>
                )}
              {errors.new_password_2 &&
                errors.new_password_2.type === "match" && (
                  <Text color="red">New passwords do not match</Text>
                )}
            </FormControl>
            <Button mt="6" type="submit">
              Reset password
            </Button>
          </form>
        </Box>
      </Center>
    </>
  );
};

export default NewPassword;
