import {
  Button,
  Center,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { FC } from "react";
import { useSubmissionsQuery } from "../../services/submissions";

const SubmissionsView: FC = () => {
  const { data, status } = useSubmissionsQuery();

  return (
    <div>
      {status === "loading" ? <Spinner /> : null}
      {status === "success" && data && data.length > 0 ? (
        <Center>
          <Table maxW={1024}>
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Submitted?</Th>
                <Th>Created By</Th>
                <Th>Created At</Th>
                <Th>Last Updated By</Th>
                <Th>Last Updated At</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.map((submission) => {
                return (
                  <Tr>
                    <Td>{submission.identifying_name}</Td>
                    <Td>{submission.submitted ? "Y" : "N"}</Td>
                    <Td>
                      {submission.created_by_user.first_name}{" "}
                      {submission.created_by_user.last_name}
                      <br />({submission.created_by_user.email})
                    </Td>
                    <Td>
                      {new Date(submission.created_date).toLocaleString(
                        "en-GB",
                        {
                          dateStyle: "long",
                          timeStyle: "medium",
                        }
                      )}
                    </Td>
                    <Td>
                      {submission.updated_by_user.first_name}{" "}
                      {submission.updated_by_user.last_name}
                      <br />({submission.updated_by_user.email})
                    </Td>
                    <Td>
                      {new Date(submission.updated_date).toLocaleString(
                        "en-GB",
                        {
                          dateStyle: "long",
                          timeStyle: "medium",
                        }
                      )}
                    </Td>
                    <Td>
                      <a href={`/submissions/${submission.id}`}>
                        <Button>View</Button>
                      </a>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Center>
      ) : null}
    </div>
  );
};

export default SubmissionsView;
