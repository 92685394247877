import { Box, Center, Spinner } from "@chakra-ui/react";
import { FC } from "react";
import { useSubmissionQuery } from "../../services/submissions";
import SubmissionSummary from "../SubmissionSummary/SubmissionSummary";
import SubmissionViewEdit from "../SubmissionViewEdit/SubmissionViewEdit";

const SubmissionView: FC<{ submissionId: string }> = (props) => {
  const { submissionId } = props;

  const { data, status, error } = useSubmissionQuery(parseInt(submissionId));

  if (status === "loading") {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  if (status === "success" && data) {
    if (data.submitted) {
      return <SubmissionSummary submissionId={parseInt(submissionId)} />;
    } else {
      return (
        <SubmissionViewEdit
          submissionId={parseInt(submissionId)}
          formVersion={data.form_version}
        />
      );
    }
  }

  if (status === "error") {
    if (error.response?.status === 403) {
      return (
        <Center>
          <Box>No permission to view this submission</Box>
        </Center>
      );
    }
    if (error.response?.status === 404) {
      return (
        <Center>
          <Box>Submission not found</Box>
        </Center>
      );
    }
  }

  return null;
};

export default SubmissionView;
