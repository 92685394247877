import { FC, ReactNode } from "react";
import Header from "../Header/Header";
import UserNavigation from "../UserNavigation/UserNavigation";

const UserWrapper: FC<{ children: ReactNode }> = (props) => {
  return (
    <>
      <Header navigation={<UserNavigation />} />
      {props.children}
    </>
  );
};

export default UserWrapper;
