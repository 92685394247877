import { Center, Spinner } from "@chakra-ui/react";
import { Redirect } from "raviger";
import { FC } from "react";
import { useFormQuery } from "../../services/forms";
import { useSubmissionQuery } from "../../services/submissions";
import { getNextQuestion } from "../../util/forms";

const SubmissionViewEdit: FC<{ submissionId: number; formVersion: string }> = (
  props
) => {
  const { submissionId, formVersion } = props;

  const { data: submissionData, status: submissionStatus } =
    useSubmissionQuery(submissionId);
  const { data: formData, status: formStatus } = useFormQuery(formVersion);

  if (submissionStatus === "loading" || formStatus === "loading") {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  if (
    submissionStatus === "success" &&
    formStatus === "success" &&
    submissionData &&
    formData
  ) {
    const { answers } = submissionData;
    const { questions } = formData;

    const firstQuestion = questions[0].questionNumber;

    if (answers.length === 0) {
      return (
        <Redirect
          to={`/submissions/${submissionId}/questions/${firstQuestion}`}
        />
      );
    } else {
      const lastAnswerIndex = answers.length - 1;
      const lastAnswer = answers[lastAnswerIndex];
      const lastAnswerQuestionNumber = lastAnswer.question_number;

      const lastQuestion = questions.find(
        (q) => q.questionNumber === lastAnswerQuestionNumber
      );

      let question_number: string | null = firstQuestion;
      if (!lastAnswer.completed) {
        question_number = lastAnswer.question_number;
      } else if (lastQuestion) {
        const nextQuestion = getNextQuestion(lastQuestion, answers);
        question_number = nextQuestion;
      }

      if (question_number) {
        if (question_number === "final") {
          return <Redirect to={`/submissions/${submissionId}/summary`} />;
        } else {
          return (
            <Redirect
              to={`/submissions/${submissionId}/questions/${question_number}`}
            />
          );
        }
      }
    }
  }

  return null;
};

export default SubmissionViewEdit;
