import { Center, Input, Spinner } from "@chakra-ui/react";
import { FC, useState } from "react";
import { useUsersQuery } from "../../services/users";
import { useDebounce } from "../../util/hooks";
import AdminUsersViewList from "./AdminUsersViewList";

const AdminViewUsers: FC = () => {
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 600);

  const { data, isFetching } = useUsersQuery();

  return (
    <div>
      <Input
        mb="5"
        placeholder="Search by name or ID"
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />
      <div>
        {isFetching ? (
          <Center>
            <Spinner />
          </Center>
        ) : null}
        {!isFetching && data ? (
          <AdminUsersViewList
            list={data.filter((user) => {
              const lowerFirstName = user.first_name.toLocaleLowerCase();
              const firstNameIncludes =
                lowerFirstName.includes(debouncedSearch);

              const lowerLastName = user.last_name.toLocaleLowerCase();
              const lastNameIncludes = lowerLastName.includes(debouncedSearch);

              const lowerEmail = user.email.toLocaleLowerCase();
              const lowerEmailIncludes = lowerEmail.includes(debouncedSearch);

              return (
                firstNameIncludes || lastNameIncludes || lowerEmailIncludes
              );
            })}
          />
        ) : null}
      </div>
    </div>
  );
};

export default AdminViewUsers;
