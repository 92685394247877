import { Box, Button, Heading, Spinner, useBoolean } from "@chakra-ui/react";
import { FC } from "react";
import { useUserQuery } from "../../services/users";
import AdminUserViewEdit from "./AdminUserViewEdit";

const AdminViewUser: FC<{ userId: string }> = (props) => {
  const { userId } = props;
  const [isEdit, { on: onEdit, off: offEdit }] = useBoolean(false);

  const { data, isFetching } = useUserQuery(userId);

  return (
    <div>
      {isFetching ? <Spinner /> : null}
      {!isFetching && data ? (
        <>
          <Heading>{data.email}</Heading>
          <Box>
            <Heading size="md" mt="4">
              Details
              {isEdit ? null : (
                <Button onClick={onEdit} ml="2">
                  Edit
                </Button>
              )}
            </Heading>
            {isEdit ? (
              <AdminUserViewEdit
                userId={data.id}
                first_name={data.first_name}
                last_name={data.last_name}
                email={data.email}
                role={data.role}
                active={data.active}
                organization_id={data.organization_id}
                onCancel={offEdit}
              />
            ) : (
              <>
                <div>ID: {data.id}</div>
                <div>First Name: {data.first_name}</div>
                <div>Last Name: {data.last_name}</div>
                <div>Email: {data.email}</div>
                <div>Organization: {data.organization.name}</div>
                <div>Role: {data.role}</div>
                <div>Active: {data.active ? "Yes" : "No"}</div>
              </>
            )}
          </Box>
        </>
      ) : null}
    </div>
  );
};

export default AdminViewUser;
