import { Center, Spinner } from "@chakra-ui/react";
import { lazy, Suspense } from "react";

const Home = lazy(() => import("./Home"));

const HomePage = () => {
  return (
    <div>
      <Suspense
        fallback={
          <Center>
            <Spinner />
          </Center>
        }
      >
        <Home />
      </Suspense>
    </div>
  );
};

export default HomePage;
