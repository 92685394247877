export const TOKEN_KEY = "NH_TOKEN";

export const getLocalValue = <T>(key: string): T | null => {
  const item = window.localStorage.getItem(key);

  if (item) {
    return JSON.parse(item) as T;
  }

  return null;
};

export const setLocalValue = (key: string, value: any) => {
  const item = JSON.stringify(value);

  window.localStorage.setItem(key, item);
};

export const removeLocalValue = (key: string) => {
  window.localStorage.removeItem(key);
};
