import {
  Box,
  Button,
  Checkbox,
  Fade,
  FormControl,
  FormLabel,
  Input,
  Text,
  useBoolean,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { OrganizationPutBody } from "../../../types/organizations.types";
import { useOrganizationUpdateMutation } from "../../services/organizations";

const AdminOrganizationViewEdit: FC<{
  orgId: number;
  active: boolean;
  internal_id: string | null;
  name: string;
  onCancel: () => void;
}> = (props) => {
  const { orgId, internal_id, name, active, onCancel } = props;

  const { register, handleSubmit, formState } = useForm<OrganizationPutBody>({
    defaultValues: { internal_id, name, active },
  });

  const [formResponseError, setFormResponseError] = useState<string | null>(
    null
  );
  const [showSuccess, { on: showSuccessOn, off: showSuccessOff }] =
    useBoolean(false);

  const updateMutation = useOrganizationUpdateMutation();

  const onSubmit = handleSubmit(async (data) => {
    setFormResponseError(null);

    try {
      const response = await updateMutation.mutateAsync({ orgId, body: data });

      if (response.status === 200) {
        showSuccessOn();

        window.setTimeout(() => {
          showSuccessOff();
        }, 2000);
      }
    } catch (e) {
      if (e instanceof AxiosError) {
        setFormResponseError(e.response?.data);
      }
    }
  });

  return (
    <form onSubmit={onSubmit}>
      <FormControl>
        <FormLabel>Internal ID:</FormLabel>
        <Input {...register("internal_id")} />
      </FormControl>
      <FormControl>
        <FormLabel>Name:</FormLabel>
        <Input {...register("name")} />
      </FormControl>
      <FormControl>
        <FormLabel>Active:</FormLabel>
        <Checkbox {...register("active")} />
      </FormControl>
      <Box>
        <Button
          colorScheme="gray"
          mr="3"
          onClick={() => {
            onCancel();
            // reset();
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          colorScheme="green"
          isLoading={formState.isSubmitting}
        >
          Save
        </Button>
      </Box>
      {formResponseError ? (
        <Text mt="1" color="red">
          {formResponseError}
        </Text>
      ) : null}
      <Fade in={showSuccess} unmountOnExit>
        <Text mt="1" color="green">
          Updated successfully
        </Text>
      </Fade>
    </form>
  );
};

export default AdminOrganizationViewEdit;
