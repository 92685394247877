import { Routes } from "raviger";
import AdminPage from "./components/Admin/AdminPage";
import AdminOrganizationsNew from "./components/AdminOrganizationsNew/AdminOrganizationsNew";
import AdminOrganizationsView from "./components/AdminOrganizationsView/AdminOrganizationsView";
import AdminOrganizationView from "./components/AdminOrganizationView/AdminOrganizationView";
import AdminSubmissionsView from "./components/AdminSubmissionsView/AdminSubmissionsView";
import AdminSubmissionView from "./components/AdminSubmissionView/AdminSubmissionView";
import AdminUsersCreate from "./components/AdminUsersCreate/AdminUsersCreate";
import AdminUsersView from "./components/AdminUsersView/AdminUsersView";
import AdminUserView from "./components/AdminUserView/AdminUserView";
import AdminWrapper from "./components/AdminWrapper/AdminWrapper";
import ChangePassword from "./components/ChangePassword/ChangePassword";
import HomePage from "./components/Home/HomePage";
import LoginPage from "./components/Login/LoginPage";
import NewPassword from "./components/NewPassword/NewPassword";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import SubmissionNew from "./components/SubmissionNew/SubmissionNew";
import SubmissionSummary from "./components/SubmissionSummary/SubmissionSummary";
import SubmissionsView from "./components/SubmissionsView/SubmissionsView";
import SubmissionView from "./components/SubmissionView/SubmissionView";
import UserWrapper from "./components/UserWrapper/UserWrapper";
import ViewQuestion from "./components/ViewQuestion/ViewQuestion";
import { loggedInGuard } from "./util/loggedInGuard";

export const HOME_ROUTE = "/";
export const LOGIN_ROUTE = "/login";
export const CHANGE_PASSWORD_ROUTE = "/change-password";
export const RESET_PASSWORD_ROUTE = "/reset-password";
export const NEW_PASSWORD_ROUTE = "/new-password";

export const ADMIN_ROUTE = "/admin";
export const ADMIN_USERS_VIEW_ROUTE = `${ADMIN_ROUTE}/users`;
export const ADMIN_USERS_CREATE_ROUTE = `${ADMIN_ROUTE}/users/new`;
export const ADMIN_USER_VIEW_ROUTE = `${ADMIN_ROUTE}/users/:userId`;
export const ADMIN_ORGANIZATIONS_VIEW_ROUTE = `${ADMIN_ROUTE}/organizations`;
export const ADMIN_ORGANIZATIONS_CREATE_ROUTE = `${ADMIN_ROUTE}/organizations/new`;
export const ADMIN_ORGANIZATION_VIEW_ROUTE = `${ADMIN_ROUTE}/organizations/:organizationId`;
export const ADMIN_SUBMISSIONS_VIEW_ROUTE = `${ADMIN_ROUTE}/submissions`;
export const ADMIN_SUBMISSION_VIEW_ROUTE = `${ADMIN_ROUTE}/submissions/:submissionId`;

export const USER_SUBMISSIONS_VIEW_ROUTE = "/submissions";
export const USER_SUBMISSION_CREATE_ROUTE = "/submissions/new";
export const USER_SUBMISSION_VIEW_ROUTE = "/submissions/:submissionId";
export const USER_SUBMISSION_QUESTION_ROUTE =
  "/submissions/:submissionId/questions/:questionNumber";
export const USER_SUBMISSION_SUMMARY_ROUTE =
  "/submissions/:submissionId/summary";

const routes: Routes<string> = {
  [HOME_ROUTE]: () => loggedInGuard(<HomePage />, LOGIN_ROUTE, HOME_ROUTE),
  [LOGIN_ROUTE]: () => <LoginPage />,
  [RESET_PASSWORD_ROUTE]: () => <ResetPassword />,
  [NEW_PASSWORD_ROUTE]: () => <NewPassword />,
  [CHANGE_PASSWORD_ROUTE]: () =>
    loggedInGuard(
      <UserWrapper>
        <ChangePassword />
      </UserWrapper>,
      LOGIN_ROUTE,
      CHANGE_PASSWORD_ROUTE
    ),
  [USER_SUBMISSIONS_VIEW_ROUTE]: () =>
    loggedInGuard(
      <UserWrapper>
        <SubmissionsView />
      </UserWrapper>,
      LOGIN_ROUTE,
      USER_SUBMISSIONS_VIEW_ROUTE
    ),
  [USER_SUBMISSION_CREATE_ROUTE]: () =>
    loggedInGuard(
      <UserWrapper>
        <SubmissionNew />
      </UserWrapper>,
      LOGIN_ROUTE,
      USER_SUBMISSION_CREATE_ROUTE
    ),
  [USER_SUBMISSION_QUESTION_ROUTE]: ({ submissionId, questionNumber }: any) =>
    loggedInGuard(
      <UserWrapper>
        <ViewQuestion
          submissionId={submissionId}
          questionNumber={questionNumber}
        />
      </UserWrapper>,
      LOGIN_ROUTE,
      `/submissions/${submissionId}/questions/${questionNumber}`
    ),
  [USER_SUBMISSION_SUMMARY_ROUTE]: ({ submissionId }: any) =>
    loggedInGuard(
      <UserWrapper>
        <SubmissionSummary submissionId={submissionId} />
      </UserWrapper>,
      LOGIN_ROUTE,
      `/submissions/${submissionId}/summary`
    ),
  [USER_SUBMISSION_VIEW_ROUTE]: ({ submissionId }: any) =>
    loggedInGuard(
      <UserWrapper>
        <SubmissionView submissionId={submissionId} />
      </UserWrapper>,
      LOGIN_ROUTE,
      `/submissions/${submissionId}`
    ),
  [ADMIN_ROUTE]: () =>
    loggedInGuard(
      <AdminWrapper>
        <AdminPage />
      </AdminWrapper>,
      LOGIN_ROUTE,
      HOME_ROUTE
    ),
  [ADMIN_USERS_CREATE_ROUTE]: () =>
    loggedInGuard(
      <AdminWrapper>
        <AdminUsersCreate />
      </AdminWrapper>,
      LOGIN_ROUTE
    ),
  [ADMIN_USERS_VIEW_ROUTE]: () =>
    loggedInGuard(
      <AdminWrapper>
        <AdminUsersView />
      </AdminWrapper>,
      LOGIN_ROUTE,
      ADMIN_USERS_VIEW_ROUTE
    ),
  [ADMIN_USER_VIEW_ROUTE]: ({ userId }: any) =>
    loggedInGuard(
      <AdminWrapper>
        <AdminUserView userId={userId} />
      </AdminWrapper>,
      LOGIN_ROUTE,
      `${ADMIN_ROUTE}/users/${userId}`
    ),
  [ADMIN_ORGANIZATIONS_CREATE_ROUTE]: () =>
    loggedInGuard(
      <AdminWrapper>
        <AdminOrganizationsNew />
      </AdminWrapper>,
      LOGIN_ROUTE,
      ADMIN_ORGANIZATIONS_CREATE_ROUTE
    ),
  [ADMIN_ORGANIZATION_VIEW_ROUTE]: ({ organizationId }: any) =>
    loggedInGuard(
      <AdminWrapper>
        <AdminOrganizationView organizationId={organizationId} />
      </AdminWrapper>,
      LOGIN_ROUTE,
      `${ADMIN_ROUTE}/organizations/${organizationId}`
    ),
  [ADMIN_ORGANIZATIONS_VIEW_ROUTE]: () =>
    loggedInGuard(
      <AdminWrapper>
        <AdminOrganizationsView />
      </AdminWrapper>,
      LOGIN_ROUTE,
      ADMIN_ORGANIZATIONS_VIEW_ROUTE
    ),
  [ADMIN_SUBMISSION_VIEW_ROUTE]: ({ submissionId }: any) =>
    loggedInGuard(
      <AdminWrapper>
        <AdminSubmissionView submissionId={submissionId} />
      </AdminWrapper>,
      LOGIN_ROUTE,
      `${ADMIN_ROUTE}/submissions/${submissionId}`
    ),
  [ADMIN_SUBMISSIONS_VIEW_ROUTE]: () =>
    loggedInGuard(
      <AdminWrapper>
        <AdminSubmissionsView />
      </AdminWrapper>,
      LOGIN_ROUTE,
      ADMIN_SUBMISSIONS_VIEW_ROUTE
    ),
};

export default routes;
