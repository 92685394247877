import {
  Box,
  Button,
  Flex,
  Heading,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import axios from "axios";
import { format } from "date-fns";
import { FC, ReactNode } from "react";
import { useAdminSubmissionQuery } from "../../services/adminSubmissions";
import { useFormQuery } from "../../services/forms";
import { getToken } from "../../util/Auth";
import { doExcel } from "../../util/makeExcel";
import doPdf from "../../util/makePdf";
import AdminAdditionalEntitiesSummary from "./AdminAdditionalEntitiesSummary";

const AdminSubmissionView: FC<{ submissionId: number }> = (props) => {
  const { submissionId } = props;

  const { status, data: submissionData } =
    useAdminSubmissionQuery(submissionId);
  const { data: formData, status: formStatus } = useFormQuery(
    submissionData ? submissionData.form_version : ""
  );

  return (
    <VStack>
      {status === "loading" || formStatus === "loading" ? <Spinner /> : null}
      {status === "success" &&
      submissionData &&
      formStatus === "success" &&
      formData ? (
        <>
          <Box mb="6">
            <Table>
              <Thead>
                <Tr>
                  <Th>Organisation</Th>
                  <Th>Name</Th>
                  <Th>Created By</Th>
                  <Th>Created On</Th>
                  <Th>Submitted</Th>
                  <Th>Processed</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>{submissionData.organization.name}</Td>
                  <Td>{submissionData.identifying_name}</Td>
                  <Td>
                    {submissionData.created_by_user.first_name}{" "}
                    {submissionData.created_by_user.last_name}
                  </Td>
                  <Td>
                    {new Date(submissionData.created_date).toLocaleDateString(
                      "en-GB",
                      {
                        dateStyle: "long",
                      }
                    )}
                  </Td>
                  <Td>{submissionData.submitted ? "Yes" : "No"}</Td>
                  <Td>{submissionData.processed ? "Yes" : "No"}</Td>
                  <Td>
                    <Button
                      onClick={() => {
                        doPdf(
                          submissionData.answers,
                          formData,
                          `${submissionData.organization.name}_${
                            submissionData.identifying_name
                          }_${format(
                            new Date(submissionData.created_date),
                            "yyyy-mm-dd"
                          )}`
                        );
                      }}
                    >
                      PDF
                    </Button>
                  </Td>
                  <Td>
                    <Button
                      onClick={() => {
                        doExcel(
                          submissionData.answers,
                          formData,
                          `${submissionData.organization.name}_${
                            submissionData.identifying_name
                          }_${format(
                            new Date(submissionData.created_date),
                            "yyyy-mm-dd"
                          )}`
                        );
                      }}
                    >
                      Excel
                    </Button>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
          <Box w="100%">
            <Heading size="md" mb="2">
              Attachments
            </Heading>
            {submissionData.attachments &&
            submissionData.attachments.length > 0 ? (
              <VStack align="stretch" mb="2">
                {submissionData.attachments.map((attachment) => {
                  return (
                    <Box key={`attachment-${attachment.id}`}>
                      <Box>{attachment.name}</Box>
                      <Button
                        size="sm"
                        onClick={async () => {
                          const dlUrl = await axios.get<string>("/api/upload", {
                            params: { attachmentId: attachment.id },
                            headers: { Authorization: getToken() },
                          });

                          const anchor = document.createElement("a");
                          anchor.href = dlUrl.data;
                          document.body.appendChild(anchor);
                          anchor.click();
                          anchor.remove();
                        }}
                      >
                        Download
                      </Button>
                    </Box>
                  );
                })}
              </VStack>
            ) : (
              "No attachments"
            )}
          </Box>
          <VStack align="stretch" spacing="6">
            {submissionData.answers.reduce((acc: ReactNode[], a, i) => {
              const question = formData.questions.find(
                (q) => q.questionNumber === a.question_number
              );

              const previousQuestion =
                i !== 0
                  ? formData.questions.find(
                      (q) =>
                        q.questionNumber ===
                        submissionData.answers[i - 1].question_number
                    )
                  : undefined;

              const showHeading =
                i === 0
                  ? true
                  : previousQuestion &&
                    question?.heading !== previousQuestion.heading;

              if (question) {
                if (question.type === "additional-entities") {
                  acc.push(
                    <Box key={a.id}>
                      {showHeading ? (
                        <Heading size="md" mb="3" mt="8">
                          {question.heading}
                        </Heading>
                      ) : null}
                      <AdminAdditionalEntitiesSummary
                        isSubmitted={submissionData.submitted}
                        submissionId={submissionId}
                        questionNumber={a.question_number}
                        questionText={question.questionText}
                        entitiesString={a.question_response}
                      />
                    </Box>
                  );
                } else if (question.type === "date") {
                  acc.push(
                    <Box
                      key={a.id}
                      borderBottomStyle="solid"
                      borderBottomColor="brand.500"
                      borderBottomWidth="1px"
                      pb="6"
                    >
                      {showHeading ? (
                        <Heading size="md" mb="3" mt="8">
                          {question.heading}
                        </Heading>
                      ) : null}
                      <Flex justifyContent="space-between">
                        <Heading size="sm">{question.questionText}</Heading>
                      </Flex>
                      <Text>
                        {new Date(a.question_response).toLocaleDateString(
                          "en-GB",
                          { dateStyle: "long" }
                        )}
                      </Text>
                    </Box>
                  );
                } else {
                  acc.push(
                    <Box
                      key={a.id}
                      borderBottomStyle="solid"
                      borderBottomColor="brand.500"
                      borderBottomWidth="1px"
                      pb="6"
                    >
                      {showHeading ? (
                        <Heading size="md" mb="3" mt="8">
                          {question.heading}
                        </Heading>
                      ) : null}
                      <Flex justifyContent="space-between">
                        <Heading size="sm">{question?.questionText}</Heading>
                      </Flex>
                      <Text whiteSpace="pre-wrap">{a.question_response}</Text>
                    </Box>
                  );
                }
              }

              return acc;
            }, [])}
          </VStack>
        </>
      ) : null}
    </VStack>
  );
};

export default AdminSubmissionView;
