import { Box, Button, Flex, Tooltip } from "@chakra-ui/react";
import { organizations } from "@prisma/client";
import { useNavigate } from "raviger";
import { FC } from "react";
import { ADMIN_ROUTE } from "../../routes";
import { useOrganizationUpdateMutation } from "../../services/organizations";

const AdminOrganizationsViewList: FC<{ list: organizations[] }> = (props) => {
  const { list } = props;

  const navigate = useNavigate();

  const orgUpdateMutation = useOrganizationUpdateMutation();

  return (
    <div>
      {list
        .sort((a, b) => {
          return a.name.localeCompare(b.name);
        })
        .map((o) => {
          const navPath = `${ADMIN_ROUTE}/organizations/${o.id}`;

          return (
            <Flex
              key={o.id}
              justify="flex-end"
              align="center"
              borderBottomWidth="1px"
              borderBottomColor="brand.500"
              borderBottomStyle="solid"
              pt="3"
              pb="3"
            >
              <Box ml="0" mr="auto">
                {o.name}
              </Box>
              <Box ml="auto" mr="3">
                <a href={navPath}>
                  <Button>View</Button>
                </a>
              </Box>
              <Box ml="3" mr="0">
                {o.active ? (
                  <Tooltip label="Deactivated organisations will not appear in lists">
                    <Button
                      colorScheme="red"
                      onClick={() => {
                        orgUpdateMutation.mutate({
                          orgId: o.id,
                          body: {
                            active: false,
                          },
                        });
                      }}
                    >
                      Deactivate
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    colorScheme="green"
                    onClick={() => {
                      orgUpdateMutation.mutate({
                        orgId: o.id,
                        body: {
                          active: true,
                        },
                      });
                    }}
                  >
                    Reactivate
                  </Button>
                )}
              </Box>
            </Flex>
          );
        })}
    </div>
  );
};

export default AdminOrganizationsViewList;
