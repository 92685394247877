import { ChevronDownIcon } from "@chakra-ui/icons";
import { Flex, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { useMatch, useNavigate } from "raviger";
import { FC, ReactNode } from "react";
import {
  USER_SUBMISSIONS_VIEW_ROUTE,
  USER_SUBMISSION_CREATE_ROUTE,
  USER_SUBMISSION_VIEW_ROUTE,
} from "../../routes";

const NavMenuButton: FC<{ children: ReactNode; isActive?: boolean }> = (
  props
) => {
  const { isActive = false } = props;
  const color = isActive ? "black" : "brand.500";
  const bgColor = isActive ? "#d9eaf3" : "transparent";

  return (
    <MenuButton
      pt="1"
      pb="1"
      pl="3"
      pr="3"
      color={color}
      bgColor={bgColor}
      _hover={{ bg: "#c5dfed", color: "black" }}
    >
      {props.children}
    </MenuButton>
  );
};

const NavMenuList: FC<{ children: ReactNode }> = (props) => {
  return (
    <MenuList
      border="1px solid rgba(0,0,0,0.15)"
      boxShadow="0 6px 12px rgb(0 0 0 / 18%);"
      borderRadius="0"
    >
      {props.children}
    </MenuList>
  );
};

const UserNavigation: FC = () => {
  const navigate = useNavigate();
  const matchSubmissions = useMatch([
    USER_SUBMISSION_CREATE_ROUTE,
    USER_SUBMISSIONS_VIEW_ROUTE,
    USER_SUBMISSION_VIEW_ROUTE,
  ]);

  return (
    <Flex justifyContent="center" mb="3" pb="2">
      <Menu>
        <NavMenuButton isActive={!!matchSubmissions}>
          Submissions <ChevronDownIcon />
        </NavMenuButton>
        <NavMenuList>
          <MenuItem
            onClick={() => {
              navigate(USER_SUBMISSION_CREATE_ROUTE);
            }}
          >
            Create
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate(USER_SUBMISSIONS_VIEW_ROUTE);
            }}
          >
            View
          </MenuItem>
        </NavMenuList>
      </Menu>
    </Flex>
  );
};

export default UserNavigation;
