import { Box, Center, Container, Spinner } from "@chakra-ui/react";
import { useNavigate } from "raviger";
import { FC } from "react";
import { NewAnswerFields } from "../../../types/Answers.types";
import { FormQuestion } from "../../../types/FormConfig.types";
import {
  useAnswerCreateMutation,
  useAnswerUpdateMutation,
} from "../../services/answers";
import { useFormQuery } from "../../services/forms";
import { useSubmissionQuery } from "../../services/submissions";
import { getNextQuestion, getPreviousQuestion } from "../../util/forms";
import StepForm from "../StepForm/StepForm";

export type OnNextQuestion = (
  question: FormQuestion,
  body: NewAnswerFields
) => void;

export type OnPreviousQuestion = (question: FormQuestion) => void;

const MainForm: FC<{ submissionId: number; questionToShow: string }> = (
  props
) => {
  const { questionToShow, submissionId } = props;

  // const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const { data: submissionData, status: submissionStatus } =
    useSubmissionQuery(submissionId);
  const { data: formData, status: formStatus } = useFormQuery(
    submissionData && submissionData.form_version
      ? submissionData.form_version
      : ""
  );

  const answer =
    submissionData && submissionData.answers.length > 0
      ? submissionData.answers.find((a) => a.question_number === questionToShow)
      : null;

  const createAnswerMutation = useAnswerCreateMutation();
  const updateAnswerMutation = useAnswerUpdateMutation();

  return (
    <Box>
      {/* <Icon onClick={onOpen} /> */}
      {/* <TermsModal isOpen={isOpen} onClose={onClose} /> */}
      {/* {submissionStatus === "success" &&
      submissionData &&
      submissionData.answers &&
      formStatus === "success" &&
      formData ? (
        <Container>
          <Center>
            {/* <Box>Question: {questionToShow}</Box> */}
            {/* <Box>
              <CircularProgress
                value={
                  (submissionData.answers.length / formData.questions.length) *
                  100
                }
              >
                <CircularProgressLabel>
                  {round(
                    (submissionData.answers.length /
                      formData.questions.length) *
                      100
                  )}
                  %
                </CircularProgressLabel>
              </CircularProgress>
            </Box>}
          </Center>
        </Container>
      ) : null} */}
      {submissionStatus === "loading" || formStatus === "loading" ? (
        <Center>
          <Spinner />
        </Center>
      ) : null}
      {createAnswerMutation.status === "loading" ? (
        <Center>
          <Spinner />
        </Center>
      ) : null}
      {submissionStatus === "success" &&
      submissionData &&
      formStatus === "success" &&
      createAnswerMutation.status !== "loading" ? (
        <StepForm
          currentQuestionNumber={questionToShow}
          submissionId={submissionId}
          formConfig={formData}
          answer={answer}
          onPrevious={(currentQuestion) => {
            if (formData) {
              const previousQuestion = getPreviousQuestion(
                currentQuestion,
                formData.questions
              );
              navigate(
                `/submissions/${submissionId}/questions/${previousQuestion}`
              );
            }
          }}
          onNext={(question, body) => {
            if (answer) {
              updateAnswerMutation
                .mutateAsync({ answerId: answer.id, body })
                .then(() => {
                  const nextQuestion = getNextQuestion(
                    question,
                    submissionData.answers
                  );

                  if (nextQuestion === "final") {
                    navigate(`/submissions/${submissionId}/summary`);
                  } else if (nextQuestion) {
                    navigate(
                      `/submissions/${submissionId}/questions/${nextQuestion}`
                    );
                  }
                });
            } else {
              createAnswerMutation.mutateAsync(body).then(() => {
                const nextQuestion = getNextQuestion(
                  question,
                  submissionData.answers
                );

                if (nextQuestion === "final") {
                  navigate(`/submissions/${submissionId}/summary`);
                } else if (nextQuestion) {
                  navigate(
                    `/submissions/${submissionId}/questions/${nextQuestion}`
                  );
                }
              });
            }
          }}
        />
      ) : null}
    </Box>
  );
};

export default MainForm;
