import { Box } from "@chakra-ui/react";
import { useRoutes } from "raviger";
import "./App.scss";
import routes from "./routes";

function App() {
  const route = useRoutes(routes);

  return <Box className="App">{route}</Box>;
}

export default App;
