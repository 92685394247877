import {
  Box,
  Button,
  Flex,
  Heading,
  Spinner,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useNavigate } from "raviger";
import { FC, ReactNode } from "react";
import { useFormQuery } from "../../services/forms";
import {
  useSubmissionQuery,
  useSubmissionUpdateMutation,
} from "../../services/submissions";
import AdditionalEntitiesSummary from "./AdditionalEntitiesSummary";

const SubmissionSummary: FC<{ submissionId: number }> = (props) => {
  const { submissionId } = props;

  const navigate = useNavigate();

  const { data: submissionData, status } = useSubmissionQuery(submissionId);
  const { data: formData, status: formStatus } = useFormQuery(
    submissionData ? submissionData.form_version : ""
  );

  const updateSubmissionMutation = useSubmissionUpdateMutation();

  const toast = useToast();

  const isSubmitted = submissionData && submissionData.submitted;

  return (
    <VStack>
      {status === "loading" || formStatus === "loading" ? <Spinner /> : null}
      {status === "success" &&
      submissionData &&
      formStatus === "success" &&
      formData ? (
        <>
          <Box mb="6">
            {isSubmitted ? (
              <Box>
                This form has been submitted and can no longer be edited
              </Box>
            ) : (
              <Box>
                <Text>This form is complete but is not yet submitted.</Text>
                <Text>
                  Please review your answers as they cannot be changed once
                  submitted.
                </Text>
                <Text>
                  When you are ready, please click the submit button at the
                  bottom of the page.
                </Text>
              </Box>
            )}
          </Box>
          <VStack align="stretch" spacing="6">
            {submissionData.answers.reduce((acc: ReactNode[], a, i) => {
              const question = formData.questions.find(
                (q) => q.questionNumber === a.question_number
              );

              const previousQuestion =
                i !== 0
                  ? formData.questions.find(
                      (q) =>
                        q.questionNumber ===
                        submissionData.answers[i - 1].question_number
                    )
                  : undefined;

              const showHeading =
                i === 0
                  ? true
                  : previousQuestion &&
                    question?.heading !== previousQuestion.heading;

              if (question) {
                if (question.type === "additional-entities") {
                  acc.push(
                    <Box key={a.id}>
                      {showHeading ? (
                        <Heading size="md" mb="3" mt="8">
                          {question.heading}
                        </Heading>
                      ) : null}
                      <AdditionalEntitiesSummary
                        isSubmitted={isSubmitted}
                        submissionId={submissionId}
                        questionNumber={a.question_number}
                        questionText={question.questionText}
                        entitiesString={a.question_response}
                      />
                    </Box>
                  );
                } else if (question.type === "date") {
                  acc.push(
                    <Box
                      key={a.id}
                      borderBottomStyle="solid"
                      borderBottomColor="brand.500"
                      borderBottomWidth="1px"
                      pb="6"
                    >
                      {showHeading ? (
                        <Heading size="md" mb="3" mt="8">
                          {question.heading}
                        </Heading>
                      ) : null}
                      <Flex justifyContent="space-between">
                        <Heading size="sm">{question.questionText}</Heading>
                        {!isSubmitted ? (
                          <Button
                            size="sm"
                            onClick={() => {
                              navigate(
                                `/submissions/${submissionId}/questions/${a.question_number}`
                              );
                            }}
                          >
                            Edit
                          </Button>
                        ) : null}
                      </Flex>
                      <Text>
                        {new Date(a.question_response).toLocaleDateString(
                          "en-GB",
                          { dateStyle: "long" }
                        )}
                      </Text>
                    </Box>
                  );
                } else {
                  acc.push(
                    <Box
                      key={a.id}
                      borderBottomStyle="solid"
                      borderBottomColor="brand.500"
                      borderBottomWidth="1px"
                      pb="6"
                    >
                      {showHeading ? (
                        <Heading size="md" mb="3" mt="8">
                          {question.heading}
                        </Heading>
                      ) : null}
                      <Flex justifyContent="space-between">
                        <Heading size="sm">{question?.questionText}</Heading>
                        {!isSubmitted ? (
                          <Button
                            size="sm"
                            onClick={() => {
                              navigate(
                                `/submissions/${submissionId}/questions/${a.question_number}`
                              );
                            }}
                          >
                            Edit
                          </Button>
                        ) : null}
                      </Flex>
                      <Text whiteSpace="pre-wrap">{a.question_response}</Text>
                    </Box>
                  );
                }
              }

              return acc;
            }, [])}
          </VStack>
          {isSubmitted ? null : (
            <Box>
              <Button
                isLoading={updateSubmissionMutation.isLoading}
                size="lg"
                mt="8"
                mb="6"
                onClick={() => {
                  updateSubmissionMutation
                    .mutateAsync({
                      submissionId,
                      body: { submitted: true },
                    })
                    .then(() => {
                      toast({
                        description: "Submitted",
                        status: "success",
                      });
                    });
                }}
              >
                Submit
              </Button>
            </Box>
          )}
        </>
      ) : null}
    </VStack>
  );
};

export default SubmissionSummary;
