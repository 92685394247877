import axios, { AxiosError } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  AdminSubmission,
  AdminSubmissionPutFields,
  AdminSubmissionWithAnswers,
} from "../../types/Submission.types";
import { getToken } from "../util/Auth";

const getAdminSubmission = async (submissionId: number) => {
  const response = await axios.get<AdminSubmissionWithAnswers>(
    "/api/admin/submissions",
    {
      params: { submissionId },
      headers: { Authorization: getToken() },
    }
  );

  if (response.status === 200) {
    return response.data;
  }

  return null;
};

const getAdminSubmissions = async () => {
  const response = await axios.get<AdminSubmission[]>(
    "/api/admin/submissions",
    { headers: { Authorization: getToken() } }
  );

  if (response.status === 200) {
    return response.data;
  }

  return [];
};

const updateAdminSubmission = async ({
  submissionId,
  body,
}: {
  submissionId: number;
  body: AdminSubmissionPutFields;
}) => {
  return axios.put<AdminSubmission>("/api/admin/submissions", body, {
    params: { submissionId: submissionId },
    headers: { Authorization: getToken() },
  });
};

const useAdminSubmissionQuery = (submissionId: number) => {
  return useQuery<AdminSubmissionWithAnswers | null, AxiosError>(
    ["admin", "submissions", submissionId],
    () => getAdminSubmission(submissionId),
    { staleTime: 1000 * 60 }
  );
};

const useAdminSubmissionsQuery = () => {
  return useQuery<AdminSubmission[], AxiosError>(
    ["admin", "submissions"],
    () => getAdminSubmissions(),
    {
      staleTime: 1000 * 60,
    }
  );
};

const useAdminSubmissionUpdateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(updateAdminSubmission, {
    onSuccess: (axiosResponse, variables) => {
      queryClient.setQueryData<AdminSubmission>(
        ["admin", "submissions", variables.submissionId],
        () => {
          return axiosResponse.data;
        }
      );

      queryClient.setQueryData<AdminSubmission[]>(
        ["admin", "submissions"],
        (existingSubmissions) => {
          if (existingSubmissions && existingSubmissions.length > 0) {
            const existingIndex = existingSubmissions.findIndex((s) => {
              return s.id === variables.submissionId;
            });

            if (existingIndex) {
              const newArray = [...existingSubmissions];
              newArray[existingIndex] = axiosResponse.data;

              return newArray;
            } else {
              return [axiosResponse.data];
            }
          } else {
            return [axiosResponse.data];
          }
        }
      );
    },
  });
};

export {
  useAdminSubmissionQuery,
  useAdminSubmissionsQuery,
  useAdminSubmissionUpdateMutation,
};
