import { RepeatIcon } from "@chakra-ui/icons";
import {
  Button,
  Center,
  Checkbox,
  Fade,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Select,
  Spinner,
  Text,
  useBoolean,
} from "@chakra-ui/react";
import { Role } from "@prisma/client";
import axios, { AxiosError } from "axios";
import { FC, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  UserNewFields,
  UserUpdateOptions,
} from "../../../types/users.create.types";
import { useOrganizationsQuery } from "../../services/organizations";
import { useUserCreateMutation } from "../../services/users";
import { getToken } from "../../util/Auth";

const AdminUsersCreate: FC = () => {
  const [showPassword, { toggle: toggleShowPassword }] = useBoolean(false);
  const [fetching, { on: fetchingOn, off: fetchingOff }] = useBoolean(false);

  const {
    register,
    handleSubmit,
    formState,
    reset: resetForm,
    setValue,
  } = useForm<UserNewFields & UserUpdateOptions>({
    defaultValues: { organization_id: "" },
  });

  const [responseError, setResponseError] = useState<null | string>(null);
  const [showSuccess, setShowSuccess] = useState(false);

  const organizations = useOrganizationsQuery();

  const createMutation = useUserCreateMutation();

  const onSubmit = handleSubmit(async (data) => {
    setResponseError(null);

    try {
      const response = await createMutation.mutateAsync(data);
      if (response.status === 201) {
        setShowSuccess(true);
        window.setTimeout(() => {
          setShowSuccess(false);
        }, 2000);
        resetForm();
      }
    } catch (e) {
      if (e instanceof AxiosError) {
        setResponseError(e.response?.data);
      }
    }
  });

  const getNewPassword = useCallback(async () => {
    fetchingOn();
    const response = await axios.get<string>("/api/auth/password", {
      params: { type: "generate" },
      headers: { Authorization: getToken() },
    });

    if (response.status === 200) {
      fetchingOff();
      setValue("password", response.data);
    } else {
      fetchingOff();
    }
  }, [fetchingOff, fetchingOn, setValue]);

  useEffect(() => {
    const go = async () => {
      await getNewPassword();
    };

    go();
  }, [getNewPassword]);

  return (
    <Center>
      <form
        autoComplete="off"
        onSubmit={onSubmit}
        style={{ maxWidth: "1024px", width: "90%" }}
      >
        <FormControl mb="1">
          <FormLabel>First Name:</FormLabel>
          <Input {...register("first_name", { required: true })} />
        </FormControl>
        <FormControl mb="1">
          <FormLabel>Last Name:</FormLabel>
          <Input {...register("last_name", { required: true })} />
        </FormControl>
        <FormControl mb="1">
          <FormLabel>Email address:</FormLabel>
          <Input
            {...register("email", { required: true })}
            type="email"
            autoComplete="off"
          />
        </FormControl>
        <FormControl mb="1">
          <FormLabel>Organisation:</FormLabel>
          {organizations.isFetching ? <Spinner /> : null}
          {organizations.isFetched && organizations.data ? (
            <Select {...register("organization_id", { required: true })}>
              <option disabled value=""></option>
              {organizations.data
                .filter((o) => o.active)
                .map((org) => {
                  return (
                    <option value={org.id} key={`organization-${org.id}`}>
                      {org.name}
                      {org.internal_id ? ` | ${org.internal_id}` : null}
                    </option>
                  );
                })}
            </Select>
          ) : null}
        </FormControl>
        <FormControl mb="1">
          <FormLabel>Set Password:</FormLabel>
          <InputGroup>
            <InputLeftElement mr="2">
              <IconButton
                size="sm"
                aria-label="Re-generate password"
                icon={<RepeatIcon />}
                onClick={() => {
                  getNewPassword();
                }}
                isLoading={fetching}
              />
            </InputLeftElement>
            <Input
              type={showPassword ? "text" : "password"}
              {...register("password", { required: true })}
              autoComplete="off"
            />
            <InputRightElement>
              <Button
                borderLeftRadius={0}
                pr="8"
                pl="8"
                onClick={toggleShowPassword}
              >
                {showPassword ? "Hide" : "Show"}
              </Button>
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <FormControl mb="1">
          <FormLabel>Role:</FormLabel>
          <Select
            {...register("role", { required: true })}
            defaultValue={Role.user}
          >
            <option value={Role.user}>User</option>
            <option value={Role.admin}>Admin</option>
          </Select>
        </FormControl>
        <FormControl mb="1">
          <FormLabel>Send new user an email?</FormLabel>
          <Checkbox {...register("sendEmailToUser")} />
        </FormControl>
        <Button type="submit" isLoading={formState.isSubmitting}>
          Create
        </Button>
        {responseError ? (
          <Text mt="1" color="red">
            {responseError}
          </Text>
        ) : null}
        <Fade in={showSuccess} unmountOnExit>
          <Text mt="1" color="green">
            Created successfully
          </Text>
        </Fade>
      </form>
    </Center>
  );
};

export default AdminUsersCreate;
