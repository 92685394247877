import {
  Button,
  Center,
  Fade,
  FormControl,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { OrganizationNewFields } from "../../../types/organizations.types";
import { useOrganizationCreateMutation } from "../../services/organizations";

const AdminOrganizationsNew: FC = () => {
  const {
    register,
    handleSubmit,
    formState,
    reset: resetForm,
  } = useForm<OrganizationNewFields>();

  const [responseError, setResponseError] = useState<null | string>(null);
  const [showSuccess, setShowSuccess] = useState(false);

  const createMutation = useOrganizationCreateMutation();

  const onSubmit = handleSubmit(async (data) => {
    setResponseError(null);

    try {
      const response = await createMutation.mutateAsync(data);

      if (response.status === 201) {
        setShowSuccess(true);
        window.setTimeout(() => {
          setShowSuccess(false);
        }, 2000);
        resetForm();
      }
    } catch (e) {
      if (e instanceof AxiosError) {
        setResponseError(e.response?.data);
      }
    }
  });

  return (
    <Center>
      <form onSubmit={onSubmit}>
        <FormControl mb="1">
          <FormLabel>Name:</FormLabel>
          <Input {...register("organization_name", { required: true })} />
        </FormControl>
        <FormControl mb="1">
          <FormLabel>Internal ID:</FormLabel>
          <Input {...register("internal_id")} />
        </FormControl>
        <Button type="submit" isLoading={formState.isSubmitting}>
          Create
        </Button>
        {responseError ? (
          <Text mt="1" color="red">
            {responseError}
          </Text>
        ) : null}
        <Fade in={showSuccess} unmountOnExit>
          <Text mt="1" color="green">
            Created successfully
          </Text>
        </Fade>
      </form>
    </Center>
  );
};

export default AdminOrganizationsNew;
