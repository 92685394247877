import { organizations, Prisma } from "@prisma/client";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  OrganizationNewFields,
  OrganizationPutBody,
} from "../../types/organizations.types";
import { getToken } from "../util/Auth";

const getOrganization = (organizationId: string) => async () => {
  try {
    const { data } = await axios.get<
      Prisma.organizationsGetPayload<{ include: { users: true } }>
    >(`/api/admin/organizations/${organizationId}`, {
      headers: { Authorization: getToken() },
    });

    return data;
  } catch (e) {
    throw e;
  }
};

const createOrganization = (body: OrganizationNewFields) => {
  return axios.post<organizations>("/api/admin/organizations", body, {
    headers: { Authorization: getToken() },
  });
};

const updateOrganization = ({
  orgId,
  body,
}: {
  orgId: number;
  body: OrganizationPutBody;
}) => {
  return axios.put<organizations>(`/api/admin/organizations/${orgId}`, body, {
    headers: { Authorization: getToken() },
  });
};

const getOrganizations = (search?: string) => async () => {
  try {
    const { data } = await axios.get<organizations[]>(
      "/api/admin/organizations",
      {
        headers: { Authorization: getToken() },
        params: { search },
      }
    );

    return data;
  } catch (e) {
    throw e;
  }
};

const useOrganizationQuery = (organizationId: string) => {
  return useQuery(
    ["organization", organizationId],
    getOrganization(organizationId),
    { staleTime: 1000 * 60 * 60 * 4 }
  );
};

const useOrganizationsQuery = (search?: string) => {
  const cacheKey = search ? ["organizations", search] : "organizations";
  return useQuery<organizations[]>(cacheKey, getOrganizations(search), {
    staleTime: 1000 * 60 * 10,
  });
};

const useOrganizationCreateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(createOrganization, {
    onSuccess: (axiosResponse, variables) => {
      queryClient.setQueryData<organizations[]>(
        "organizations",
        (existingOrgs) => {
          if (existingOrgs) {
            return [...existingOrgs, axiosResponse.data];
          }

          return [axiosResponse.data];
        }
      );
    },
  });
};

const useOrganizationUpdateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(updateOrganization, {
    onSuccess: (axiosResponse, variables) => {
      queryClient.setQueryData<organizations[]>(
        "organizations",
        (existingOrgs) => {
          if (existingOrgs) {
            const remainingOrgs = existingOrgs.reduce(
              (acc: organizations[], org) => {
                if (org.id !== variables.orgId) {
                  acc.push(org);
                }
                return acc;
              },
              []
            );
            return [...remainingOrgs, axiosResponse.data];
          }

          return [axiosResponse.data];
        }
      );

      queryClient.setQueryData<organizations>(
        ["organization", variables.orgId.toString()],
        () => {
          return axiosResponse.data;
        }
      );
    },
  });
};

export {
  useOrganizationsQuery,
  useOrganizationQuery,
  useOrganizationCreateMutation,
  useOrganizationUpdateMutation,
};
