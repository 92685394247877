import { Button, Flex, Textarea } from "@chakra-ui/react";
import { Answer } from "@prisma/client";
import { FC, useState } from "react";
import { OpenTextQuestion as OpenTextQuestionType } from "../../../types/FormConfig.types";
import { OnNextQuestion, OnPreviousQuestion } from "../MainForm/MainForm";

const OpenTextQuestion: FC<{
  questionIndex: number;
  question: OpenTextQuestionType;
  submissionId: number;
  onNext: OnNextQuestion;
  onPrevious: OnPreviousQuestion;
  answer: Answer | null | undefined;
}> = (props) => {
  const { questionIndex, question, submissionId, onNext, onPrevious, answer } =
    props;

  const [response, setResponse] = useState(
    answer ? answer.question_response : ""
  );

  return (
    <>
      <Textarea
        maxW="60ch"
        width="100%"
        value={response}
        onChange={(e) => {
          setResponse(e.target.value);
        }}
      />
      <Flex justifyContent="space-between" width="90%" maxW="50ch">
        {questionIndex === 0 ? null : (
          <Button
            mt="8"
            ml="5"
            mr="5"
            colorScheme="brandGray"
            onClick={() => {
              onPrevious(question);
            }}
            >
            Previous
          </Button>
        )}
        <Button
          mt="8"
          ml="5"
          mr="5"
          disabled={question.optional ? false : response === ""}
          onClick={() => {
            onNext(question, {
              question_number: question.questionNumber,
              question_response: response,
              submission_id: submissionId,
              completed: true,
            });
          }}
        >
          Next
        </Button>
      </Flex>
    </>
  );
};

export default OpenTextQuestion;
