import {
  Box,
  Button,
  Checkbox,
  Fade,
  FormControl,
  FormLabel,
  Input,
  Select,
  Spinner,
  Text,
  useBoolean,
} from "@chakra-ui/react";
import { Role } from "@prisma/client";
import { AxiosError } from "axios";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useOrganizationsQuery } from "../../services/organizations";
import { useUserUpdateMutation } from "../../services/users";

const AdminUserViewEdit: FC<{
  userId: number;
  first_name: string;
  last_name: string;
  email: string;
  role: Role;
  active: boolean;
  organization_id: number;
  onCancel: () => void;
}> = (props) => {
  const {
    userId,
    first_name,
    last_name,
    email,
    role,
    active,
    organization_id,
    onCancel,
  } = props;

  const { register, handleSubmit, formState } = useForm({
    defaultValues: {
      first_name,
      last_name,
      email,
      role,
      active,
      organization_id,
    },
  });

  const [formResponseError, setFormResponseError] = useState<string | null>(
    null
  );
  const [showSuccess, { on: showSuccessOn, off: showSuccessOff }] =
    useBoolean(false);

  const {
    data: organizationsData,
    isFetching: organizationsFetching,
    isFetched: organizationsIsFetched,
  } = useOrganizationsQuery();
  const updateMutation = useUserUpdateMutation(userId.toString());

  const onSubmit = handleSubmit(async (data) => {
    setFormResponseError(null);

    try {
      const response = await updateMutation.mutateAsync({ userId, body: data });

      if (response.status === 200) {
        showSuccessOn();

        window.setTimeout(() => {
          showSuccessOff();
        }, 2000);
      }
    } catch (e) {
      if (e instanceof AxiosError) {
        setFormResponseError(e.response?.data);
      }
    }
  });

  return (
    <form onSubmit={onSubmit}>
      <FormControl>
        <FormLabel>First Name:</FormLabel>
        <Input {...register("first_name")} />
      </FormControl>
      <FormControl>
        <FormLabel>Last Name:</FormLabel>
        <Input {...register("last_name")} />
      </FormControl>
      <FormControl>
        <FormLabel>Email:</FormLabel>
        <Input {...register("email")} />
      </FormControl>
      <FormControl>
        <FormLabel>Organization:</FormLabel>
        {organizationsFetching ? <Spinner /> : null}
        {organizationsIsFetched && organizationsData ? (
          <Select
            {...register("organization_id", {
              required: true,
              valueAsNumber: true,
            })}
          >
            {organizationsData
              .filter((o) => o.active)
              .map((org) => {
                return (
                  <option value={org.id} key={`organization-${org.id}`}>
                    {org.name}
                    {org.internal_id ? ` | ${org.internal_id}` : null}
                  </option>
                );
              })}
          </Select>
        ) : null}
      </FormControl>
      <FormControl>
        <FormLabel>Role:</FormLabel>
        <Select {...register("role")}>
          {Object.entries(Role).map(([key, value]) => {
            return (
              <option value={value} key={key}>
                {key}
              </option>
            );
          })}
        </Select>
      </FormControl>
      <FormControl>
        <FormLabel>Active:</FormLabel>
        <Checkbox {...register("active")} />
      </FormControl>
      <Box>
        <Button
          colorScheme="gray"
          mr="3"
          onClick={() => {
            onCancel();
            // reset();
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          colorScheme="green"
          isLoading={formState.isSubmitting}
        >
          Save
        </Button>
      </Box>
      {formResponseError ? (
        <Text mt="1" color="red">
          {formResponseError}
        </Text>
      ) : null}
      <Fade in={showSuccess} unmountOnExit>
        <Text mt="1" color="green">
          Updated successfully
        </Text>
      </Fade>
    </form>
  );
};

export default AdminUserViewEdit;
