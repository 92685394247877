import {
  Box,
  Button,
  Center,
  Heading,
  List,
  ListIcon,
  ListItem,
  VStack,
} from "@chakra-ui/react";
import { FC } from "react";
import { MdCheckCircle } from "react-icons/md";

const SubmissionNewIntro: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <>
      <Heading mb="5" textAlign="center">
        New Submission
      </Heading>
      <VStack>
        <div>
          <Box mb="2">
            Before we begin please make sure you have the following documents
            available:
          </Box>
          <Center mb="2">
            <List spacing="3">
              <ListItem>
                <ListIcon as={MdCheckCircle} color="green.500" />
                Trust Deeds
              </ListItem>
              <ListItem>
                <ListIcon as={MdCheckCircle} color="green.500" />
                Wills
              </ListItem>
              <ListItem>
                <ListIcon as={MdCheckCircle} color="green.500" />
                Trustee details or passports
              </ListItem>
              <ListItem>
                <ListIcon as={MdCheckCircle} color="green.500" />
                Any other relevant documents
              </ListItem>
            </List>
          </Center>
        </div>
        <div>
          <Button onClick={onClick}>Start</Button>
        </div>
      </VStack>
    </>
  );
};

export default SubmissionNewIntro;
