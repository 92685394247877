import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { AttachmentWithInfo } from "../../types/Attachment.types";
import { SubmissionWithAnswers } from "../../types/Submission.types";
import { getToken } from "../util/Auth";

const postAttachment = async ({
  file,
  submissionId,
}: {
  file: File;
  submissionId: number;
}) => {
  const addUploadResponse = await axios.post<
    AttachmentWithInfo & { uploadUrl: string }
  >(
    "/api/upload",
    { name: file.name, contentType: file.type },
    {
      headers: {
        Authorization: getToken(),
      },
      params: {
        submissionId,
      },
    }
  );

  if (addUploadResponse.status === 201) {
    const formData = new FormData();
    formData.append("file", file);

    const uploadFileResponse = await axios.put(
      addUploadResponse.data.uploadUrl,
      formData,
      {
        headers: {
          "Content-Type": file.type,
        },
      }
    );

    return addUploadResponse;
  }
};

const deleteAttachment = async ({ attachmentId }: { attachmentId: number }) => {
  return axios.delete<AttachmentWithInfo>(`/api/upload/`, {
    headers: { Authorization: getToken() },
    params: { attachmentId },
  });
};

const useAttachmentUploadMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(postAttachment, {
    onSuccess: (axiosResponse, variables) => {
      queryClient.setQueryData<SubmissionWithAnswers | undefined>(
        ["submission", variables.submissionId],
        (existingSubmission) => {
          if (existingSubmission && axiosResponse) {
            existingSubmission.attachments = [
              ...existingSubmission.attachments,
              axiosResponse.data,
            ];
          }

          return existingSubmission;
        }
      );
    },
  });
};

const useAttachmentDeleteMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteAttachment, {
    onSuccess: (axiosResponse, variables) => {
      queryClient.setQueryData<SubmissionWithAnswers | undefined>(
        ["submission", axiosResponse.data.submissionId],
        (existingSubmission) => {
          if (existingSubmission) {
            existingSubmission.attachments =
              existingSubmission.attachments.filter(
                (a) => a.id !== variables.attachmentId
              );
          }

          return existingSubmission;
        }
      );
    },
  });
};

export { useAttachmentUploadMutation, useAttachmentDeleteMutation };
