import { FC } from "react";
import MainForm from "../MainForm/MainForm";

type ViewQuestionProps = {
  submissionId: string;
  questionNumber: string;
};

const ViewQuestion: FC<ViewQuestionProps> = (props) => {
  const { submissionId, questionNumber } = props;

  return (
    <div>
      <MainForm
        questionToShow={questionNumber}
        submissionId={parseInt(submissionId)}
      />
    </div>
  );
};

export default ViewQuestion;
