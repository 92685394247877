import { Box, Button, Flex, Tooltip } from "@chakra-ui/react";
import { useNavigate } from "raviger";
import { FC } from "react";
import { UserWithoutPassword } from "../../../types/Users.types";
import { ADMIN_ROUTE } from "../../routes";
import { useUserUpdateMutation } from "../../services/users";

const UserItem: FC<{ u: UserWithoutPassword }> = (props) => {
  const { u } = props;

  const navigate = useNavigate();

  const userUpdateMutation = useUserUpdateMutation(`user-${u.id}`);

  const navPath = `${ADMIN_ROUTE}/users/${u.id}`;

  return (
    <Flex
      justify="flex-end"
      align="center"
      borderBottomWidth="1px"
      borderBottomColor="brand.500"
      borderBottomStyle="solid"
      pt="3"
      pb="3"
    >
      <Box ml="0" mr="auto">
        {u.email}
      </Box>
      <Box ml="auto" mr="3">
        <a href={navPath}>
          <Button>View</Button>
        </a>
      </Box>
      <Box ml="3" mr="0">
        {u.active ? (
          <Tooltip label="Deactivated users cannot log in">
            <Button
              isLoading={userUpdateMutation.isLoading}
              colorScheme="red"
              onClick={() => {
                userUpdateMutation.mutate({
                  userId: u.id,
                  body: {
                    active: false,
                  },
                });
              }}
            >
              Deactivate
            </Button>
          </Tooltip>
        ) : (
          <Button
            isLoading={userUpdateMutation.isLoading}
            colorScheme="green"
            onClick={() => {
              userUpdateMutation.mutate({
                userId: u.id,
                body: {
                  active: true,
                },
              });
            }}
          >
            Reactivate
          </Button>
        )}
      </Box>
    </Flex>
  );
};

const AdminUsersViewList: FC<{ list: UserWithoutPassword[] }> = (props) => {
  const { list } = props;

  return (
    <div>
      {list
        .sort((a, b) => {
          return a.email.localeCompare(b.email);
        })
        .map((u) => {
          return <UserItem u={u} key={`user-${u.id}`} />;
        })}
    </div>
  );
};

export default AdminUsersViewList;
