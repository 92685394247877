import { ChevronDownIcon } from "@chakra-ui/icons";
import { Flex, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { useMatch } from "raviger";
import { FC, ReactNode } from "react";
import {
  ADMIN_ORGANIZATIONS_CREATE_ROUTE,
  ADMIN_ORGANIZATIONS_VIEW_ROUTE,
  ADMIN_ORGANIZATION_VIEW_ROUTE,
  ADMIN_SUBMISSIONS_VIEW_ROUTE,
  ADMIN_SUBMISSION_VIEW_ROUTE,
  ADMIN_USERS_CREATE_ROUTE,
  ADMIN_USERS_VIEW_ROUTE,
  ADMIN_USER_VIEW_ROUTE,
} from "../../routes";

const NavMenuButton: FC<{ children: ReactNode; isActive?: boolean }> = (
  props
) => {
  const { isActive = false } = props;
  const color = isActive ? "black" : "brand.500";
  const bgColor = isActive ? "#d9eaf3" : "transparent";

  return (
    <MenuButton
      pt="1"
      pb="1"
      pl="3"
      pr="3"
      color={color}
      bgColor={bgColor}
      _hover={{ bg: "#c5dfed", color: "black" }}
    >
      {props.children}
    </MenuButton>
  );
};

const NavMenuList: FC<{ children: ReactNode }> = (props) => {
  return (
    <MenuList
      border="1px solid rgba(0,0,0,0.15)"
      boxShadow="0 6px 12px rgb(0 0 0 / 18%);"
      borderRadius="0"
    >
      {props.children}
    </MenuList>
  );
};

const AdminNavigation: FC = () => {
  const matchSub = useMatch([
    ADMIN_SUBMISSIONS_VIEW_ROUTE,
    ADMIN_SUBMISSION_VIEW_ROUTE,
  ]);
  const matchOrg = useMatch([
    ADMIN_ORGANIZATIONS_CREATE_ROUTE,
    ADMIN_ORGANIZATIONS_VIEW_ROUTE,
    ADMIN_ORGANIZATION_VIEW_ROUTE,
  ]);
  const matchUsers = useMatch([
    ADMIN_USERS_CREATE_ROUTE,
    ADMIN_USERS_VIEW_ROUTE,
    ADMIN_USER_VIEW_ROUTE,
  ]);

  return (
    <Flex justifyContent="center" mb="3" pb="2">
      <Menu>
        <NavMenuButton isActive={!!matchSub}>
          Submissions <ChevronDownIcon />
        </NavMenuButton>
        <NavMenuList>
          <a href={ADMIN_SUBMISSIONS_VIEW_ROUTE}>
            <MenuItem>View</MenuItem>
          </a>
        </NavMenuList>
      </Menu>
      <Menu>
        <NavMenuButton isActive={!!matchOrg}>
          Organisations <ChevronDownIcon />
        </NavMenuButton>
        <NavMenuList>
          <a href={ADMIN_ORGANIZATIONS_CREATE_ROUTE}>
            <MenuItem>Create</MenuItem>
          </a>
          <a href={ADMIN_ORGANIZATIONS_VIEW_ROUTE}>
            <MenuItem>View</MenuItem>
          </a>
        </NavMenuList>
      </Menu>
      <Menu>
        <NavMenuButton isActive={!!matchUsers}>
          Users <ChevronDownIcon />
        </NavMenuButton>
        <NavMenuList>
          <a href={ADMIN_USERS_CREATE_ROUTE}>
            <MenuItem>Create</MenuItem>
          </a>
          <a href={ADMIN_USERS_VIEW_ROUTE}>
            <MenuItem>View</MenuItem>
          </a>
        </NavMenuList>
      </Menu>
    </Flex>
  );
};

export default AdminNavigation;
