import { Center, Spinner } from "@chakra-ui/react";
import { FC, lazy, Suspense } from "react";

const Login = lazy(() => import("./Login"));

const LoginPage: FC = () => {
  return (
    <div>
      <Suspense
        fallback={
          <Center>
            <Spinner />
          </Center>
        }
      >
        <Login />
      </Suspense>
    </div>
  );
};
export default LoginPage;
