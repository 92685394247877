import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useNavigate } from "raviger";
import { FC, ReactNode } from "react";
import { CHANGE_PASSWORD_ROUTE, LOGIN_ROUTE } from "../../routes";
import { getTokenProperty, isLoggedIn, useLogout } from "../../util/Auth";

const UserDropdown: FC<{ userEmail: string | number | null }> = (props) => {
  const { userEmail } = props;
  const navigate = useNavigate();

  const logout = useLogout();

  if (userEmail) {
    return (
      <Menu>
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
          {userEmail}
        </MenuButton>
        <MenuList>
          <MenuItem
            onClick={() => {
              navigate(CHANGE_PASSWORD_ROUTE);
            }}
          >
            Change Password
          </MenuItem>
          <MenuItem
            onClick={() => {
              logout();
              navigate(LOGIN_ROUTE);
            }}
          >
            Logout
          </MenuItem>
        </MenuList>
      </Menu>
    );
  }

  return null;
};

const Header: FC<{ navigation?: ReactNode }> = ({ navigation }) => {
  return (
    <Box
      as="header"
      mb="6"
      pb="1"
      borderBottomStyle="solid"
      borderBottomWidth="1px"
      borderBottomColor="brand.500"
    >
      <Flex justify="space-between" align="center" pt="0" pb="0" pr="0" pl="8">
        <Box>
          <Heading as="h1">Trust Registration</Heading>
          {isLoggedIn() ? (
            <UserDropdown userEmail={getTokenProperty("email")} />
          ) : null}
        </Box>
        {navigation ? <Box alignSelf="flex-end">{navigation}</Box> : null}
        <Image
          src="/nh-logo.svg"
          alt="Nunn Hayward"
          width="50%"
          maxWidth="300px"
        />
      </Flex>
    </Box>
  );
};

export default Header;
