import { Box, Button, Flex, Heading } from "@chakra-ui/react";
import { Answer } from "@prisma/client";
import { FC, useState } from "react";
import { DateQuestion as DateQuestionType } from "../../../types/FormConfig.types";
import Datepicker from "../Datepicker/Datepicker";
import { OnNextQuestion, OnPreviousQuestion } from "../MainForm/MainForm";

const DateQuestion: FC<{
  questionIndex: number;
  question: DateQuestionType;
  submissionId: number;
  onNext: OnNextQuestion;
  onPrevious: OnPreviousQuestion;
  answer: Answer | null | undefined;
}> = (props) => {
  const { questionIndex, question, submissionId, onNext, onPrevious, answer } =
    props;

  const [selectedDate, setDateResponse] = useState<Date | undefined>(
    answer ? new Date(answer.question_response) : undefined
  );

  return (
    <div>
      <Datepicker
        selected={selectedDate}
        onDateSelected={(date) => {
          const userTzOffset = date.date.getTimezoneOffset() * 60000;
          const dateWithNoTz = new Date(date.date.getTime() - userTzOffset);

          setDateResponse(dateWithNoTz);
        }}
      />
      {selectedDate ? (
        <Box mt="3" mb="3" textAlign="center">
          <Heading size="md">Selected date</Heading>
          <Box>
            {selectedDate.toLocaleDateString("en-GB", {
              dateStyle: "long",
            })}
          </Box>
        </Box>
      ) : null}
      <Flex
        justifyContent="space-between"
        width="90%"
        maxW="50ch"
        ml="auto"
        mr="auto"
      >
        {questionIndex === 0 ? null : (
          <Button
            colorScheme="brandGray"
            mt="6"
            onClick={() => {
              onPrevious(question);
            }}
          >
            Previous
          </Button>
        )}
        <Button
          disabled={selectedDate === null || selectedDate === undefined}
          mt="6"
          onClick={() => {
            if (selectedDate) {
              onNext(question, {
                question_response: selectedDate.toISOString(),
                question_number: question.questionNumber,
                submission_id: submissionId,
                completed: true,
              });
            }
          }}
        >
          Next
        </Button>
      </Flex>
    </div>
  );
};

export default DateQuestion;
