import { Answer } from "@prisma/client";
import { format } from "date-fns";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { Content, TDocumentDefinitions } from "pdfmake/interfaces";
import { FormConfig, FormQuestion } from "../../types/FormConfig.types";

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

const makeAnswer = (answer: string, type: FormQuestion["type"] | undefined) => {
  switch (type) {
    case "date":
      return {
        text: format(new Date(answer), "dd MMMM yyyy"),
        style: "answer",
      };

    case "additional-entities": {
      const additionalEntities = JSON.parse(answer);

      const list = {
        style: "answer",
        stack: additionalEntities.reduce((acc: any, entity: any) => {
          Object.entries(entity).forEach(([entityLabel, entityValue]) => {
            acc.push({
              stack: [
                { text: `${entityLabel}:`, bold: true },
                { text: `${entityValue}` },
              ],
              margin: [0, 0, 0, 5],
            });
          });

          return acc;
        }, []),
      };

      return list;
    }

    default:
      return { text: answer, style: "answer" };
  }
};

const doPdf = (answers: Answer[], form: FormConfig, filename: string) => {
  const content: Content = answers.map((answer) => {
    const question = form.questions.find(
      (q) => q.questionNumber === answer.question_number
    );

    const questionText = question
      ? question.questionText
      : "Could not find question";

    return {
      stack: [
        { text: questionText, style: "question", headlineLevel: 1 },
        makeAnswer(answer.question_response, question?.type),
      ],
    };
  });

  const dd: TDocumentDefinitions = {
    content,
    styles: {
      question: { fontSize: 17, bold: true, margin: [0, 0, 0, 5] },
      answer: { fontSize: 15, margin: [0, 0, 0, 20] },
    },
    pageBreakBefore: function (
      currentNode,
      followingNodesOnPage,
      nodesOnNextPage,
      previousNodesOnPage
    ) {
      return (
        currentNode.headlineLevel === 1 && followingNodesOnPage.length === 0
      );
    },
  };

  pdfMake.createPdf(dd).download(filename);
};

export default doPdf;
