import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Text,
  VStack,
} from "@chakra-ui/react";
import axios from "axios";
import { useNavigate } from "raviger";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { LOGIN_ROUTE } from "../../routes";
import Header from "../Header/Header";

type FormData = { email: string };

const ResetPassword: FC = () => {
  const navigate = useNavigate();
  const [sent, setSent] = useState(false);

  const { register, handleSubmit } = useForm<FormData>({
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      const response = await axios.get("/api/auth/password", {
        params: { type: "reset", email: data.email },
      });

      if (response.status === 200) {
        setSent(true);
      }
    } catch (e) {
      console.error(e);
    }
  });

  return (
    <>
      <Header />
      <Center>
        {sent ? (
          <VStack>
            <Text>Request for password reset sent</Text>
            <Button
              onClick={() => {
                navigate(LOGIN_ROUTE);
              }}
            >
              Login
            </Button>
          </VStack>
        ) : (
          <Box width="100%" maxW="50ch">
            <form onSubmit={onSubmit}>
              <FormControl mb="1em">
                <FormLabel>Email address:</FormLabel>
                <InputGroup>
                  <Input {...register("email", { required: true })} />
                </InputGroup>
              </FormControl>
              <Button mt="6" type="submit">
                Reset password
              </Button>
            </form>
          </Box>
        )}
      </Center>
    </>
  );
};

export default ResetPassword;
