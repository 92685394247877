import { Center, Input, Spinner } from "@chakra-ui/react";
import { FC, useState } from "react";
import { useOrganizationsQuery } from "../../services/organizations";
import { useDebounce } from "../../util/hooks";
import AdminOrganizationsViewList from "./AdminOrganizationsViewList";

const AdminOrganizationsView: FC = () => {
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 600);
  const { data, isFetching } = useOrganizationsQuery();

  return (
    <div>
      <Input
        mb="5"
        placeholder="Search by name or ID"
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />
      <div>
        {isFetching ? (
          <Center>
            <Spinner />
          </Center>
        ) : null}
        {!isFetching && data ? (
          <AdminOrganizationsViewList
            list={data.filter((org) => {
              const lowerName = org.name.toLocaleLowerCase();
              const nameIncludes = lowerName.includes(debouncedSearch);
              const internalIdIncludes = org.internal_id
                ? org.internal_id.includes(debouncedSearch)
                : false;
              return nameIncludes || internalIdIncludes;
            })}
          />
        ) : null}
      </div>
    </div>
  );
};

export default AdminOrganizationsView;
