import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { UserNewFields, UserPutBody } from "../../types/users.create.types";
import { UserWithoutPassword } from "../../types/Users.types";
import { getToken } from "../util/Auth";

// const getUser = (userId: string) => async () => {
//   try {
//     const { data } = await axios.get<UserWithoutPassword>(
//       `/api/admin/users/${userId}`,
//       {
//         headers: { Authorization: getToken() },
//       }
//     );

//     return data;
//   } catch (e) {
//     console.error(e);
//     return null;
//   }
// };

const createUser = (body: UserNewFields) => {
  return axios.post<UserWithoutPassword>("/api/admin/users", body, {
    headers: { Authorization: getToken() },
  });
};

const updateUser = ({
  userId,
  body,
}: {
  userId: number;
  body: UserPutBody;
}) => {
  return axios.put<UserWithoutPassword>(`/api/admin/users/${userId}`, body, {
    headers: { Authorization: getToken() },
  });
};

const getUsers = (search?: string) => async () => {
  try {
    const { data } = await axios.get<UserWithoutPassword[]>(
      "/api/admin/users",
      {
        headers: { Authorization: getToken() },
        params: { ...(search !== undefined && { search }) },
      }
    );

    return data;
  } catch (e) {
    console.error(e);
    return [];
  }
};

const useUserQuery = (userId: string) => {
  return useQuery(["users"], getUsers(), {
    staleTime: 1000 * 60 * 10,
    select: (data) => {
      return data.find((u) => u.id.toString() === userId);
    },
  });
};

const useUsersQuery = (search?: string) => {
  const cacheKey = search ? ["users", search] : "users";

  return useQuery<UserWithoutPassword[]>(cacheKey, getUsers(search), {
    staleTime: 1000 * 60 * 10,
  });
};

const useUserCreateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(createUser, {
    onSuccess: (axiosResponse) => {
      queryClient.setQueryData<UserWithoutPassword[]>(
        "users",
        (existingUsers) => {
          if (existingUsers) {
            return [...existingUsers, axiosResponse.data];
          }

          return [axiosResponse.data];
        }
      );
    },
  });
};

const useUserUpdateMutation = (key: string) => {
  const queryClient = useQueryClient();

  return useMutation(updateUser, {
    mutationKey: key,
    onSuccess: (axiosResponse, variables) => {
      queryClient.setQueryData<UserWithoutPassword[]>(
        "users",
        (existingUsers) => {
          if (existingUsers) {
            const remainingUsers = existingUsers.reduce(
              (acc: UserWithoutPassword[], user) => {
                if (user.id !== variables.userId) {
                  acc.push(user);
                }

                return acc;
              },
              []
            );

            return [...remainingUsers, axiosResponse.data];
          }

          return [axiosResponse.data];
        }
      );
    },
  });
};

export {
  useUserQuery,
  useUsersQuery,
  useUserCreateMutation,
  useUserUpdateMutation,
};
