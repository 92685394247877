import {
  Box,
  Button,
  Center,
  Heading,
  Spinner,
  useBoolean,
} from "@chakra-ui/react";
import { FC } from "react";
import { useOrganizationQuery } from "../../services/organizations";
import AdminOrganizationViewEdit from "./AdminOrganizationViewEdit";

const AdminOrganizationView: FC<{ organizationId: string }> = (props) => {
  const { organizationId } = props;
  const [isEdit, { on: onEdit, off: offEdit }] = useBoolean(false);
  const { data, isFetching, isError, failureCount } =
    useOrganizationQuery(organizationId);

  return (
    <div>
      {isError ? (
        <Center>
          Something went wrong. Please try again later. Tried {failureCount}{" "}
          times
        </Center>
      ) : null}
      {isFetching ? <Spinner /> : null}
      {!isFetching && data ? (
        <>
          <Heading>{data.name}</Heading>
          <Box>
            <Heading size="md" mt="4">
              Details
              {isEdit ? null : (
                <Button onClick={onEdit} ml="2">
                  Edit
                </Button>
              )}
            </Heading>
            {isEdit ? (
              <AdminOrganizationViewEdit
                orgId={data.id}
                active={data.active}
                internal_id={data.internal_id}
                name={data.name}
                onCancel={offEdit}
              />
            ) : (
              <>
                <div>ID: {data.id}</div>
                <div>Name: {data.name}</div>
                <div>Internal ID: {data.internal_id ?? "None set"}</div>
                <div>Active: {data.active ? "Yes" : "No"}</div>
              </>
            )}
          </Box>
          <Heading size="md" mt="4">
            Users
          </Heading>
          {data.users.map((user) => {
            return <div key={`user-${user.id}`}>Email: {user.email}</div>;
          })}
        </>
      ) : null}
    </div>
  );
};

export default AdminOrganizationView;
