import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import { navigate } from "raviger";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { SubmissionNewFields } from "../../../types/Submission.types";
import { useSubmissionCreateMutation } from "../../services/submissions";

const SubmissionNewForm: FC = () => {
  const {
    register,
    handleSubmit,
    formState,
    reset: resetForm,
  } = useForm<SubmissionNewFields>();

  const [responseError, setResponseError] = useState<null | string>(null);

  const createMutation = useSubmissionCreateMutation();

  const onSubmit = handleSubmit(async (data) => {
    setResponseError(null);

    try {
      const response = await createMutation.mutateAsync(data);

      if (response.status === 201) {
        resetForm();
        navigate(`/submissions/${response.data.id}`);
      }
    } catch (e) {
      if (e instanceof AxiosError) {
        setResponseError(e.response?.data);
      }
    }
  });

  return (
    <VStack>
      <Text fontSize="4xl" mb="0">
        Please provide a name for this submission
      </Text>
      <Text fontSize="2xl">It will help you find this submission later</Text>
      <form onSubmit={onSubmit}>
        <FormControl mb="4">
          <FormLabel>Name:</FormLabel>
          <Input {...register("identifying_name", { required: true })} />
        </FormControl>
        <HStack justifyContent="flex-end">
          <Button type="submit" isLoading={formState.isSubmitting}>
            Next
          </Button>
        </HStack>
        {responseError ? (
          <Text mt="1" color="red">
            {responseError}
          </Text>
        ) : null}
      </form>
    </VStack>
  );
};

export default SubmissionNewForm;
