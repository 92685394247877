import { Center, Spinner } from "@chakra-ui/react";
import { lazy, Suspense } from "react";

const Admin = lazy(() => import("./Admin"));

const AdminPage = () => {
  return (
    <div>
      <Suspense
        fallback={
          <Center>
            <Spinner />
          </Center>
        }
      >
        <Admin />
      </Suspense>
    </div>
  );
};

export default AdminPage;
