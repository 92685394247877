import { Box, Button, VStack } from "@chakra-ui/react";
import { FC } from "react";
import { ChoiceQuestion as ChoiceQuestionType } from "../../../types/FormConfig.types";
import { OnNextQuestion, OnPreviousQuestion } from "../MainForm/MainForm";

const ChoiceQuestion: FC<{
  questionIndex: number;
  question: ChoiceQuestionType;
  submissionId: number;
  onNext: OnNextQuestion;
  onPrevious: OnPreviousQuestion;
}> = (props) => {
  const { questionIndex, question, submissionId, onNext, onPrevious } = props;

  return (
    <>
      <VStack align="stretch">
        {question.choices.map((choice, cI) => {
          return (
            <Button
              key={`choice-${cI}`}
              // mt="4"
              mb="2"
              colorScheme="blue"
              onClick={() => {
                onNext(question, {
                  question_response: choice.choiceText,
                  question_number: question.questionNumber,
                  submission_id: submissionId,
                  completed: true,
                });
              }}
            >
              {choice.choiceText}
            </Button>
          );
        })}
      </VStack>
      {questionIndex === 0 ? null : (
        <Box w="90%" maxW="50ch">
          <Button
            mt="6"
            colorScheme="brandGray"
            onClick={() => {
              onPrevious(question);
            }}
          >
            Previous
          </Button>
        </Box>
      )}
    </>
  );
};

export default ChoiceQuestion;
