import { Redirect } from "raviger";
import { isLoggedIn } from "./Auth";

type Guard = (
  next: JSX.Element,
  redirectPath: string,
  returnPath?: string
) => JSX.Element;

export const loggedInGuard: Guard = (next, redirectPath, returnPath) => {
  if (isLoggedIn()) {
    return next;
  } else {
    return (
      <Redirect
        to={redirectPath}
        replace={false}
        query={returnPath ? { returnPath } : undefined}
      />
    );
  }
};
