import {
  Box,
  Button,
  Center,
  Heading,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import format from "date-fns/format";
import { FC } from "react";
import { utils, writeFile } from "xlsx";
import {
  useAdminSubmissionsQuery,
  useAdminSubmissionUpdateMutation,
} from "../../services/adminSubmissions";

const AdminSubmissionsView: FC = () => {
  const { data, isSuccess, isFetching } = useAdminSubmissionsQuery();

  const submissionMutation = useAdminSubmissionUpdateMutation();

  return (
    <Box>
      <Heading size="md" textAlign="center">
        All submissions
      </Heading>
      {isFetching ? (
        <Center>
          <Spinner />
        </Center>
      ) : null}
      {isSuccess ? (
        <>
          <Center>
            <Button
              mt="3"
              mb="3"
              size="sm"
              onClick={() => {
                const rows = data.map((submission) => {
                  return {
                    Organisation: submission.organization.name,
                    "Organisation Internal ID":
                      submission.organization.internal_id,
                    Name: submission.identifying_name,
                    "Submitted?": submission.submitted ? "Yes" : "No",
                    "Processed?": submission.processed ? "Yes" : "No",
                    "Created By": submission.created_by_user.email,
                    "Created At": format(
                      new Date(submission.created_date),
                      "dd MMMM yyyy HH:mm:ss"
                    ),
                    "Last Updated By": submission.updated_by_user.email,
                    "Last Updated At": format(
                      new Date(submission.updated_date),
                      "dd MMMM yyyy HH:mm:ss"
                    ),
                  };
                });

                const worksheet = utils.json_to_sheet(rows);
                const workbook = utils.book_new();

                utils.book_append_sheet(workbook, worksheet);

                writeFile(workbook, "NH Trust Submissions.xlsx");
              }}
            >
              Download
            </Button>
          </Center>
          <Center>
            <Table size="sm" maxW={1024}>
              <Thead>
                <Tr>
                  <Th>Organisation</Th>
                  <Th>Name</Th>
                  <Th>Submitted?</Th>
                  <Th>Processed?</Th>
                  <Th>Created By</Th>
                  <Th>Created At</Th>
                  <Th>Last Updated By</Th>
                  <Th>Last Updated At</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.map((submission, i) => {
                  return (
                    <Tr key={`admin-submission-${i}`}>
                      <Td>{submission.organization.name}</Td>
                      <Td>{submission.identifying_name}</Td>
                      <Td>{submission.submitted ? "Y" : "N"}</Td>
                      <Td>{submission.processed ? "Y" : "N"}</Td>
                      <Td>
                        {submission.created_by_user.first_name}{" "}
                        {submission.created_by_user.last_name}
                        <br />({submission.created_by_user.email})
                      </Td>
                      <Td>
                        {new Date(submission.created_date).toLocaleString(
                          "en-GB",
                          {
                            dateStyle: "long",
                            timeStyle: "medium",
                          }
                        )}
                      </Td>
                      <Td>
                        {submission.updated_by_user.first_name}{" "}
                        {submission.updated_by_user.last_name}
                        <br />({submission.updated_by_user.email})
                      </Td>
                      <Td>
                        {new Date(submission.updated_date).toLocaleString(
                          "en-GB",
                          {
                            dateStyle: "long",
                            timeStyle: "medium",
                          }
                        )}
                      </Td>
                      <Td>
                        <a href={`/admin/submissions/${submission.id}`}>
                          <Button size="sm" mb="2">
                            View
                          </Button>
                        </a>
                        <Button
                          size="sm"
                          mb="2"
                          isLoading={submissionMutation.isLoading}
                          onClick={() => {
                            submissionMutation.mutate({
                              submissionId: submission.id,
                              body: {
                                processed: !submission.processed,
                              },
                            });
                          }}
                        >
                          {submission.processed
                            ? "Mark as unprocessed"
                            : "Mark as processed"}
                        </Button>
                        <Button
                          size="sm"
                          disabled={!submission.submitted}
                          onClick={() => {
                            submissionMutation.mutate({
                              submissionId: submission.id,
                              body: { submitted: false },
                            });
                          }}
                        >
                          Mark as not submitted
                        </Button>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Center>
        </>
      ) : null}
    </Box>
  );
};

export default AdminSubmissionsView;
