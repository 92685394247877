import { Box, Fade, Heading, VStack } from "@chakra-ui/react";
import { Answer } from "@prisma/client";
import { FC } from "react";
import { FormConfig } from "../../../types/FormConfig.types";
import { OnNextQuestion, OnPreviousQuestion } from "../MainForm/MainForm";
import AdditionalEntitiesQuestion from "./AdditionalEntitiesQuestion";
import ChoiceQuestion from "./ChoiceQuestion";
import DateQuestion from "./DateQuestion";
import FileUploadQuestion from "./FileUploadQuestion";
import OpenTextQuestion from "./OpenTextQuestion";

type StepFormProps = {
  currentQuestionNumber: string;
  onNext: OnNextQuestion;
  onPrevious: OnPreviousQuestion;
  answer?: Answer | null;
  formConfig: FormConfig | null | undefined;
  submissionId: number;
};

const StepForm: FC<StepFormProps> = (props) => {
  const {
    currentQuestionNumber,
    onNext,
    onPrevious,
    answer,
    formConfig,
    submissionId,
  } = props;

  if (!formConfig) {
    return null;
  }

  return (
    <div
      style={{
        width: "100%",
        position: "relative",
        height: "100%",
        margin: "0 auto",
      }}
    >
      <div
        style={{
          width: "70%",
          position: "relative",
          height: "80%",
          margin: "0 auto",
        }}
      >
        {formConfig.questions.map((question, qI) => {
          const isIn = question.questionNumber === currentQuestionNumber;

          return (
            <Fade
              key={`question-${qI}`}
              in={isIn}
              unmountOnExit={true}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                minHeight: 0,
                paddingBottom: '3rem'
              }}
            >
              <Heading size="lg" mb="4" textAlign="center">{question.heading}</Heading>
              <VStack>
                <Box mb="3" textAlign="center">{question.questionText}</Box>
                {question.type === "choice" ? (
                  <ChoiceQuestion
                    questionIndex={qI}
                    question={question}
                    submissionId={submissionId}
                    onNext={onNext}
                    onPrevious={onPrevious}
                  />
                ) : null}
                {question.type === "open-text" ? (
                  <OpenTextQuestion
                    questionIndex={qI}
                    question={question}
                    submissionId={submissionId}
                    onNext={onNext}
                    onPrevious={onPrevious}
                    answer={answer}
                  />
                ) : null}
                {question.type === "date" ? (
                  <DateQuestion
                    questionIndex={qI}
                    question={question}
                    submissionId={submissionId}
                    onNext={onNext}
                    onPrevious={onPrevious}
                    answer={answer}
                  />
                ) : null}
                {question.type === "additional-entities" ? (
                  <AdditionalEntitiesQuestion
                    questionIndex={qI}
                    question={question}
                    submissionId={submissionId}
                    onNext={onNext}
                    onPrevious={onPrevious}
                    answer={answer}
                  />
                ) : null}
                {question.type === "file-upload" ? (
                  <FileUploadQuestion
                    questionIndex={qI}
                    question={question}
                    submissionId={submissionId}
                    onNext={onNext}
                    onPrevious={onPrevious}
                  />
                ) : null}
              </VStack>
            </Fade>
          );
        })}
      </div>
    </div>
  );
};

export default StepForm;
