import axios from "axios";
import { useQuery } from "react-query";
import { FormConfig } from "../../types/FormConfig.types";
import { getToken } from "../util/Auth";

const getForm = async (formVersion: string) => {
  const response = await axios.get<FormConfig>(
    `/api/forms?form_version=${formVersion}`,
    { headers: { Authorization: getToken() } }
  );

  if (response.status === 200) {
    return response.data;
  }

  return null;
};

const useFormQuery = (formVersion: string) => {
  return useQuery(["form", formVersion], () => getForm(formVersion), {
    staleTime: 1000 * 60 * 60 * 4,
    enabled: !!formVersion,
  });
};

export { useFormQuery };
