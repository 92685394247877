import {
  ChakraProvider,
  extendTheme,
  withDefaultColorScheme,
} from "@chakra-ui/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import App from "./App";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const nhTheme = extendTheme(
  {
    fonts: {
      heading: "Omnes",
      body: "Open Sans",
    },
    colors: {
      brand: {
        "50": "#E5F6FF",
        "100": "#B8E5FF",
        "200": "#8AD4FF",
        "300": "#5CC3FF",
        "400": "#2EB2FF",
        "500": "#00629B",
        "500.hover": "#ddecf4",
        "600": "#0081CC",
        "700": "#006199",
        "800": "#004066",
        "900": "#002033",
      },
      brandGray: {
        "50": "#F1F2F4",
        "100": "#D8DCDF",
        "200": "#BEC5CA",
        "300": "#A5AFB6",
        "400": "#8C98A1",
        "500": "#5b6770",
        "600": "#5B6871",
        "700": "#454E54",
        "800": "#2E3438",
        "900": "#171A1C",
      },
    },
  },
  withDefaultColorScheme({ colorScheme: "brand" })
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
    },
  },
});

root.render(
  <ChakraProvider theme={nhTheme}>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </QueryClientProvider>
  </ChakraProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
