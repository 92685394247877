import { AddIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Select,
  Text,
  Textarea,
  Tooltip,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { Answer } from "@prisma/client";
import { isDate } from "lodash";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import {
  AdditionalEntitiesQuestion as AdditionalEntitiesQuestionType,
  AdditionalEntitiesQuestionQuestion,
} from "../../../types/FormConfig.types";
import {
  useAnswerCreateMutation,
  useAnswerUpdateMutation,
} from "../../services/answers";
import { OnNextQuestion, OnPreviousQuestion } from "../MainForm/MainForm";

type AdditionalEntitiesQuestionProps = {
  questionIndex: number;
  question: AdditionalEntitiesQuestionType;
  submissionId: number;
  onNext: OnNextQuestion;
  onPrevious: OnPreviousQuestion;
  answer: Answer | null | undefined;
};

type EntityAnswers = { [qText: string]: string | number };

const Entity: FC<{
  fieldsConfig: AdditionalEntitiesQuestionQuestion[];
  fields: EntityAnswers;
  onChange: any;
  loading: boolean;
}> = (props) => {
  if (props.fields) {
    return (
      <Flex
        flexWrap="wrap"
        pl="3"
        borderLeftStyle="solid"
        borderLeftWidth="2px"
        borderLeftColor="brand.500"
      >
        {Object.entries(props.fields).map((f, i) => {
          const value = f[1];
          const valueToUse = isDate(value) ? value.toISOString() : value;

          const question = props.fieldsConfig.find((fC) => {
            return fC.questionText === f[0];
          });

          const questionType = question?.type;

          return (
            <FormControl
              key={`field-${i}`}
              mb="3"
              mr="3"
              flexBasis="auto"
              flexGrow="0"
              width="auto"
            >
              <FormLabel fontSize="sm" whiteSpace="pre-line">
                {f[0]}:
              </FormLabel>
              {questionType === "open-text" ? (
                <Textarea
                  disabled={props.loading}
                  width="auto"
                  value={valueToUse ?? ""}
                  onChange={(e) => {
                    props.onChange(f[0], e.target.value);
                  }}
                />
              ) : null}
              {questionType === "single-text" ||
              questionType === "date" ||
              questionType === "currency" ||
              questionType === undefined ? (
                <Input
                  disabled={props.loading}
                  width="auto"
                  value={valueToUse ?? ""}
                  onChange={(e) => {
                    props.onChange(f[0], e.target.value);
                  }}
                />
              ) : null}
              {questionType === "choice" ? (
                <Select
                  maxW="30ch"
                  disabled={props.loading}
                  value={valueToUse}
                  onChange={(e) => {
                    props.onChange(f[0], e.target.value);
                  }}
                >
                  <option disabled value=""></option>
                  {question?.choices.map((c, i) => {
                    return (
                      <option key={`${question.questionText}-${i}`}>
                        {c.choiceText}
                      </option>
                    );
                  })}
                </Select>
              ) : null}
            </FormControl>
          );
        })}
      </Flex>
    );
  } else {
    return null;
  }
};

const AdditionalEntitiesQuestion: FC<AdditionalEntitiesQuestionProps> = (
  props
) => {
  const { questionIndex, question, submissionId, onNext, onPrevious, answer } =
    props;
  const toast = useToast();
  const toastIdRef = useRef<string | number>("");

  const emptyEntity = question.questions.reduce((acc: EntityAnswers, qs) => {
    acc[qs.questionText] = "";

    return acc;
  }, {});

  const [openEntity, setOpenEntity] = useState(0);
  const [answerId, setAnswerId] = useState<number | false>(
    answer ? answer.id : false
  );

  const [entities, setEntities] = useState(
    answer
      ? (JSON.parse(answer.question_response) as EntityAnswers[])
      : [{ ...emptyEntity }]
  );

  const [error, setError] = useState<string[] | null>(null);

  const createAnswerMutation = useAnswerCreateMutation();
  const updateAnswerMutation = useAnswerUpdateMutation();

  const validate = useCallback(
    (entitiesToSet: EntityAnswers[]) => {
      const requiredFields = question.questions.filter((q) => {
        if (q.type === "choice") {
          return true;
        }

        return !q.optional;
      });

      const newErrors = entitiesToSet.reduce((acc: string[], e, i) => {
        const entityNumber = i + 1;

        const requiredFieldsThatAreBlank = requiredFields.filter((f) => {
          const answerText = e[f.questionText];
          return answerText === "" || answerText === null;
        });

        if (
          requiredFieldsThatAreBlank &&
          requiredFieldsThatAreBlank.length > 0
        ) {
          const errorText = `Number ${entityNumber} has blank fields: ${requiredFieldsThatAreBlank
            .map((f) => f.questionText)
            .join(", ")}`;

          acc.push(errorText);
        }

        return acc;
      }, []);

      setError(newErrors);
    },
    [question.questions]
  );

  useEffect(() => {
    validate(entities);
  }, [validate, entities]);

  const onClick = (entitiesToSet: EntityAnswers[], i: number, extra?: any) => {
    validate(entitiesToSet);
    toastIdRef.current = toast({ description: "Saving.." });

    if (answerId) {
      updateAnswerMutation
        .mutateAsync({
          answerId: answerId,
          body: {
            question_number: question.questionNumber,
            question_response: JSON.stringify(entitiesToSet),
            submission_id: submissionId,
            completed: false,
          },
        })
        .then(() => {
          setOpenEntity(i);
          extra && extra();
          toast.close(toastIdRef.current);
        });
    } else {
      createAnswerMutation
        .mutateAsync({
          question_number: question.questionNumber,
          question_response: JSON.stringify(entitiesToSet),
          submission_id: submissionId,
          completed: false,
        })
        .then((r) => {
          setAnswerId(r.data.id);
          setOpenEntity(i);
          toast.close(toastIdRef.current);
          extra && extra();
        });
    }
  };

  const showFields =
    createAnswerMutation.status !== "loading" &&
    updateAnswerMutation.status !== "loading";

  return (
    <Box maxWidth="1024px">
      <HStack mb="3" alignItems="stretch" spacing={3}>
        <VStack flexGrow={1} flexShrink="0" alignItems="end">
          {entities.map((e, i) => {
            const firstAnswer = question.questions[0]
              ? e[question.questions[0].questionText]
              : "";
            const secondAnswer = question.questions[1]
              ? e[question.questions[1].questionText]
              : "";
            const thirdAnswer = question.questions[2]
              ? e[question.questions[2].questionText]
              : "";

            const entityLabel =
              firstAnswer === "" && secondAnswer === "" && thirdAnswer === ""
                ? "New"
                : `${firstAnswer} ${secondAnswer} ${thirdAnswer}`;

            return (
              <HStack key={i} alignItems="center" justifyItems="flex-end">
                <Text
                  whiteSpace="nowrap"
                  flexShrink="0"
                  textOverflow="ellipsis"
                  width="13ch"
                  overflow="hidden"
                  textAlign="right"
                  textDecoration={i === openEntity ? "underline" : "none"}
                >
                  {i + 1}: {entityLabel}
                </Text>
                <Tooltip label="Edit">
                  <IconButton
                    disabled={i === openEntity}
                    aria-label={`Edit entity ${i + 1}`}
                    icon={<EditIcon />}
                    onClick={() => {
                      onClick(entities, i);
                    }}
                  />
                </Tooltip>
                <Tooltip label="Remove">
                  <IconButton
                    colorScheme="red"
                    aria-label={`Remove entity ${i + 1}`}
                    icon={<DeleteIcon />}
                    onClick={() => {
                      const newEntities = [
                        ...entities.slice(0, i),
                        ...entities.slice(i + 1),
                      ];
                      const newOpen = openEntity === i ? i - 1 : openEntity;
                      onClick(newEntities, newOpen, () => {
                        setEntities(newEntities);
                      });
                    }}
                  />
                </Tooltip>
              </HStack>
            );
          })}
          <Tooltip label="Add">
            <IconButton
              aria-label="Add new entity"
              icon={<AddIcon />}
              onClick={() => {
                onClick(entities, entities.length);
                setEntities([...entities, { ...emptyEntity }]);
              }}
            />
          </Tooltip>
        </VStack>
        {entities && entities.length > 0 ? (
          <Entity
            loading={!showFields}
            fieldsConfig={question.questions}
            fields={entities[openEntity]}
            onChange={(name: string, value: string) => {
              const newEnt = { ...entities[openEntity], [name]: value };
              setEntities([
                ...entities.slice(0, openEntity),
                newEnt,
                ...entities.slice(openEntity + 1),
              ]);
            }}
          />
        ) : null}
      </HStack>
      {error && error.length > 0 && (
        <VStack>
          {error.map((e, i) => {
            return (
              <Text key={`error-${i}`} color="red">
                {e}
              </Text>
            );
          })}
        </VStack>
      )}
      <HStack justifyContent="space-between" width="90%" maxW="50ch">
        {questionIndex === 0 ? null : (
          <Button
            mt="8"
            ml="5"
            mr="5"
            colorScheme="brandGray"
            onClick={() => {
              onPrevious(question);
            }}
          >
            Previous
          </Button>
        )}
        <Button
          mt="8"
          ml="5"
          mr="5"
          disabled={error !== null && Object.keys(error).length > 0}
          onClick={() => {
            onNext(question, {
              question_number: question.questionNumber,
              question_response: JSON.stringify(entities),
              submission_id: submissionId,
              completed: true,
            });
          }}
        >
          Next
        </Button>
      </HStack>
    </Box>
  );
};

export default AdditionalEntitiesQuestion;
