import { Answer } from "@prisma/client";
import { FormQuestion } from "../../types/FormConfig.types";

export const getNextQuestion = (
  currentQuestion: FormQuestion,
  answers: Answer[]
): "final" | string | null => {
  if (currentQuestion.type === "choice") {
    const choices = currentQuestion.choices;
    const answer = answers.find(
      (a) => a.question_number === currentQuestion.questionNumber
    );
    const chosenChoice = choices.find(
      (c) => c.choiceText === answer?.question_response
    );

    const next = chosenChoice?.goToQuestion;

    if (next) {
      if (typeof next === "string") {
        return next;
      } else {
        const { dependsOnQuestionNumber, branches } = next;

        const toGoTo = getNextDependsOnQuestionNumber(
          dependsOnQuestionNumber,
          branches,
          answers
        );

        if (toGoTo) {
          return toGoTo;
        } else {
          return null;
        }
      }
    } else if (currentQuestion.finalQuestion) {
      return "final";
    } else {
      return null;
    }
  } else {
    if (currentQuestion.goToQuestion) {
      if (typeof currentQuestion.goToQuestion === "string") {
        return currentQuestion.goToQuestion;
      } else {
        const { dependsOnQuestionNumber, branches } =
          currentQuestion.goToQuestion;
        const next = getNextDependsOnQuestionNumber(
          dependsOnQuestionNumber,
          branches,
          answers
        );

        if (next) {
          return next;
        } else {
          return null;
        }
      }
    } else if (currentQuestion.finalQuestion) {
      return "final";
    } else {
      return null;
    }
  }
};

export const getNextDependsOnQuestionNumber = (
  dependsOnQuestionNumber: string,
  branches: { answer: string; goToQuestion: string }[],
  answers: Answer[]
): string | false => {
  const answer = answers.find(
    (a) => a.question_number === dependsOnQuestionNumber
  );

  if (answer) {
    const branch = branches.find((b) => b.answer === answer.question_response);

    if (branch) {
      return branch.goToQuestion;
    }
  }

  return false;
};

export const getPreviousQuestion = (
  currentQuestion: FormQuestion,
  questions: FormQuestion[]
): string => {
  const questionNumber = currentQuestion.questionNumber;

  const previousQuestion = questions.find((q) => {
    if (q.type === "choice") {
      const hasCurrentInChoice = q.choices.some((c) => {
        if (typeof c.goToQuestion === "string") {
          return c.goToQuestion === questionNumber;
        } else {
          const hasCurrentInBranch = c.goToQuestion.branches.some(
            (b) => b.goToQuestion === questionNumber
          );

          return hasCurrentInBranch;
        }
      });

      return hasCurrentInChoice;
    }

    if (q.type === "additional-entities") {
      if (q.goToQuestion) {
        if (typeof q.goToQuestion === "string") {
          return q.goToQuestion === questionNumber;
        } else {
          const hasCurrentInBranch = q.goToQuestion.branches.some(
            (b) => b.goToQuestion === questionNumber
          );

          return hasCurrentInBranch;
        }
      } else {
        return false;
      }
    }
    if (q.type === "date") {
      if (q.goToQuestion) {
        if (typeof q.goToQuestion === "string") {
          return q.goToQuestion === questionNumber;
        } else {
          const hasCurrentInBranch = q.goToQuestion.branches.some(
            (b) => b.goToQuestion === questionNumber
          );

          return hasCurrentInBranch;
        }
      } else {
        return false;
      }
    }
    if (q.type === "file-upload") {
      if (q.goToQuestion) {
        if (typeof q.goToQuestion === "string") {
          return q.goToQuestion === questionNumber;
        } else {
          const hasCurrentInBranch = q.goToQuestion.branches.some(
            (b) => b.goToQuestion === questionNumber
          );

          return hasCurrentInBranch;
        }
      } else {
        return false;
      }
    }
    if (q.type === "open-text") {
      if (q.goToQuestion) {
        if (typeof q.goToQuestion === "string") {
          return q.goToQuestion === questionNumber;
        } else {
          const hasCurrentInBranch = q.goToQuestion.branches.some(
            (b) => b.goToQuestion === questionNumber
          );

          return hasCurrentInBranch;
        }
      } else {
        return false;
      }
    }

    return false;
  });

  if (previousQuestion) {
    return previousQuestion.questionNumber;
  }

  console.warn("Couldn't find previous");
  return "";
};
