import { Answer } from "@prisma/client";
import format from "date-fns/format";
import { utils, writeFile } from "xlsx";
import { FormConfig, FormQuestion } from "../../types/FormConfig.types";

type ExcelAnswerRow = {
  "Question Number": string;
  Question: string;
  Answer: string;
};

const makeRow = (
  question: FormQuestion | undefined,
  answer: string
): ExcelAnswerRow | ExcelAnswerRow[] => {
  if (question) {
    switch (question.type) {
      case "date":
        return {
          "Question Number": question.questionNumber,
          Question: question.questionText,
          Answer: format(new Date(answer), "dd MMMM yyyy"),
        };

      case "additional-entities": {
        const additionalEntities: any[] = JSON.parse(answer);

        const list: ExcelAnswerRow[] = additionalEntities.reduce(
          (acc: ExcelAnswerRow[], entity: [string, string], index) => {
            Object.entries(entity).forEach(([entityLabel, entityValue]) => {
              acc.push({
                "Question Number": "",
                Question: `${index + 1} - ${entityLabel}`,
                Answer: entityValue,
              });
            });

            return acc;
          },
          []
        );

        return [
          {
            "Question Number": question.questionNumber,
            Question: question.questionText,
            Answer: "",
          },
          ...list,
        ];
      }

      default:
        return {
          "Question Number": question.questionNumber,
          Question: question.questionText,
          Answer: answer,
        };
    }
  }

  return [];
};

export const doExcel = async (
  answers: Answer[],
  form: FormConfig,
  filename: string
) => {
  const rows = answers.flatMap((answer) => {
    const question = form.questions.find(
      (q) => q.questionNumber === answer.question_number
    );

    return makeRow(question, answer.question_response);
  });

  const worksheet = utils.json_to_sheet(rows);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet);

  writeFile(workbook, `${filename}.xlsx`);
};
