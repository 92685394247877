import { Answer } from "@prisma/client";
import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { NewAnswerFields } from "../../types/Answers.types";
import { SubmissionWithAnswers } from "../../types/Submission.types";
import { getToken } from "../util/Auth";

const createAnswer = async (body: NewAnswerFields) => {
  return axios.post<Answer>("/api/answers", body, {
    headers: { Authorization: getToken() },
  });
};

const updateAnswer = async ({
  answerId,
  body,
}: {
  answerId: number;
  body: NewAnswerFields;
}) => {
  return axios.put<Answer>(`/api/answers/${answerId}`, body, {
    headers: { Authorization: getToken() },
  });
};

const useAnswerCreateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(createAnswer, {
    onSuccess: (axiosResponse) => {
      queryClient.setQueryData<SubmissionWithAnswers | undefined>(
        ["submission", axiosResponse.data.submission_id],
        (existingSubmission) => {
          if (existingSubmission) {
            existingSubmission.answers = [
              ...existingSubmission.answers,
              axiosResponse.data,
            ];

            return existingSubmission;
          }
        }
      );
    },
  });
};

const useAnswerUpdateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(updateAnswer, {
    onSuccess: (axiosResponse, variables) => {
      queryClient.setQueryData<SubmissionWithAnswers | undefined>(
        ["submission", axiosResponse.data.submission_id],
        (existingSubmission) => {
          if (existingSubmission) {
            const existingAnswerIndex = existingSubmission.answers.findIndex(
              (a) => {
                return a.id === variables.answerId;
              }
            );

            if (existingAnswerIndex > -1) {
              existingSubmission.answers[existingAnswerIndex] =
                axiosResponse.data;
            }
          }

          return existingSubmission;
        }
      );
    },
  });
};

export { useAnswerCreateMutation, useAnswerUpdateMutation };
