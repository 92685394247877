import { Box } from "@chakra-ui/react";
import { FC, useState } from "react";
import SubmissionNewForm from "./SubmissionNewForm";
import SubmissionNewIntro from "./SubmissionNewIntro";

const SubmissionNew: FC = () => {
  const [index, setIndex] = useState(0);

  const transition = "transform 0.4s ease-in-out";

  return (
    <Box position="relative" display="flex" overflow="hidden">
      <Box
        flexGrow="0"
        flexShrink="0"
        flexBasis="100vw"
        transition={transition}
        transform={index !== 0 ? "translateX(-100%)" : ""}
      >
        <SubmissionNewIntro
          onClick={() => {
            setIndex(1);
          }}
        />
      </Box>
      <Box
        flexGrow="0"
        flexShrink="0"
        flexBasis="100vw"
        transition={transition}
        transform={index !== 0 ? "translateX(-100%)" : ""}
      >
        <SubmissionNewForm />
      </Box>
    </Box>
  );
};

export default SubmissionNew;
