import { Prisma, Submission } from "@prisma/client";
import axios, { AxiosError } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  SubmissionPutFields,
  SubmissionWithAnswers,
} from "../../types/Submission.types";
import { getToken } from "../util/Auth";

const getSubmission = async (submissionId: number) => {
  const response = await axios.get<SubmissionWithAnswers>(
    `/api/submissions?submissionId=${submissionId}`,
    {
      headers: { Authorization: getToken() },
    }
  );

  if (response.status === 200) {
    return response.data;
  }

  return null;
};

const createSubmission = (body: {
  identifying_name: Submission["identifying_name"];
}) => {
  return axios.post<SubmissionWithAnswers>("/api/submissions", body, {
    headers: { Authorization: getToken() },
  });
};

const getSubmissions = async () => {
  const response = await axios.get<
    Prisma.SubmissionGetPayload<{
      include: {
        created_by_user: true;
        updated_by_user: true;
      };
    }>[]
  >("/api/submissions", {
    headers: { Authorization: getToken() },
  });

  if (response.status === 200) {
    return response.data;
  }

  return [];
};

const updateSubmission = async ({
  submissionId,
  body,
}: {
  submissionId: number;
  body: SubmissionPutFields;
}) => {
  return axios.put<SubmissionWithAnswers>(
    `/api/submissions?submissionId=${submissionId}`,
    body,
    {
      headers: { Authorization: getToken() },
    }
  );
};

const useSubmissionQuery = (submissionId: number) => {
  return useQuery<SubmissionWithAnswers | null, AxiosError>(
    ["submission", submissionId],
    () => getSubmission(submissionId),
    {
      staleTime: 1000 * 60 * 10,
    }
  );
};

const useSubmissionsQuery = () => {
  return useQuery<
    Prisma.SubmissionGetPayload<{
      include: {
        created_by_user: true;
        updated_by_user: true;
      };
    }>[],
    AxiosError
  >(["submissions"], () => getSubmissions(), {
    staleTime: 1000 * 60 * 10,
  });
};

const useSubmissionCreateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(createSubmission, {
    onSuccess: (axiosResponse) => {
      queryClient.setQueryData<SubmissionWithAnswers[]>(
        "submissions",
        (existingSubmissions) => {
          if (existingSubmissions) {
            return [...existingSubmissions, axiosResponse.data];
          }

          return [axiosResponse.data];
        }
      );
    },
  });
};

const useSubmissionUpdateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(updateSubmission, {
    onSuccess: (axiosResponse, variables) => {
      queryClient.setQueryData<SubmissionWithAnswers>(
        ["submission", variables.submissionId],
        () => {
          return axiosResponse.data;
        }
      );
    },
  });
};

export {
  useSubmissionQuery,
  useSubmissionsQuery,
  useSubmissionCreateMutation,
  useSubmissionUpdateMutation,
};
