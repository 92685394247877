import { DeleteIcon } from "@chakra-ui/icons";
import { Button, IconButton } from "@chakra-ui/react";
import { FC } from "react";
import { useAttachmentDeleteMutation } from "../../services/attachments";

const FileUploadDeleteButton: FC<{ attachmentId: number }> = ({
  attachmentId,
}) => {
  const deleteMutation = useAttachmentDeleteMutation();

  return (
    <IconButton
      colorScheme="red"
      isLoading={deleteMutation.isLoading}
      onClick={() => {
        deleteMutation.mutate({ attachmentId: attachmentId });
      }}
      aria-label="Delete upload"
      icon={<DeleteIcon />}
    />
  );
};

export default FileUploadDeleteButton;
