import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useBoolean,
  useToast,
} from "@chakra-ui/react";
import axios, { AxiosError } from "axios";
import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { getToken, logout } from "../../util/Auth";

type FormData = {
  current_password: string;
  new_password: string;
  new_password_2: string;
};

const ChangePassword: FC = () => {
  const [showCurrent, { toggle: toggleCurrent }] = useBoolean(false);
  const [showNew, { toggle: toggleNew }] = useBoolean(false);
  const [showNew2, { toggle: toggleNew2 }] = useBoolean(false);

  const toast = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    setError,
    clearErrors,
    watch,
  } = useForm<FormData>({
    defaultValues: {
      current_password: "",
      new_password: "",
      new_password_2: "",
    },
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      const response = await axios.put(
        "/api/auth/password",
        {
          currentPassword: data.current_password,
          newPassword: data.new_password_2,
        },
        {
          params: { change_password: "yes" },
          headers: { Authorization: getToken() },
        }
      );

      if (response.status === 200) {
        toast({
          status: "success",
          description: "Password changed successfully",
          isClosable: true,
        });

        logout();
      }
    } catch (e) {
      if (e instanceof AxiosError) {
        toast({
          status: "error",
          isClosable: true,
          description:
            e.response?.status === 403
              ? "Incorrect current password"
              : "Error updating password",
        });
      }
    }
  });

  const vales = watch(["new_password", "new_password_2"]);

  useEffect(() => {
    if (touchedFields.new_password_2) {
      if (vales[0] !== vales[1]) {
        if (errors.new_password_2?.type === "match") {
        } else {
          setError("new_password_2", {
            message: "New passwords do not match",
            type: "match",
          });
        }
      } else {
        if (errors.new_password_2?.type === "match") {
          clearErrors("new_password_2");
        }
      }
    }
  }, [setError, clearErrors, touchedFields, vales, errors]);

  return (
    <Center>
      <Box width="100%" maxW="50ch">
        <form onSubmit={onSubmit}>
          <FormControl mb="1em">
            <FormLabel>Current password:</FormLabel>
            <InputGroup>
              <Input
                type={showCurrent ? "text" : "password"}
                {...register("current_password", { required: true })}
              />
              <InputRightElement>
                <Button
                  borderLeftRadius={0}
                  pr="8"
                  pl="8"
                  onClick={toggleCurrent}
                >
                  {showCurrent ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>
            {errors.current_password &&
              errors.current_password.type === "required" && (
                <Text color="red">Please enter current password</Text>
              )}
          </FormControl>
          <FormControl mb="1em">
            <FormLabel>New password:</FormLabel>
            <InputGroup>
              <Input
                type={showNew ? "text" : "password"}
                {...register("new_password", { required: true })}
              />
              <InputRightElement>
                <Button borderLeftRadius={0} pr="8" pl="8" onClick={toggleNew}>
                  {showNew ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>
            {errors.new_password && errors.new_password.type === "required" && (
              <Text color="red">Please enter new password</Text>
            )}
          </FormControl>
          <FormControl mb="1em">
            <FormLabel>Confirm new password:</FormLabel>
            <InputGroup>
              <Input
                type={showNew2 ? "text" : "password"}
                {...register("new_password_2", { required: true })}
              />
              <InputRightElement>
                <Button borderLeftRadius={0} pr="8" pl="8" onClick={toggleNew2}>
                  {showNew2 ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>
            {errors.new_password_2 &&
              errors.new_password_2.type === "required" && (
                <Text color="red">Please confirm new password</Text>
              )}
            {errors.new_password_2 &&
              errors.new_password_2.type === "match" && (
                <Text color="red">New passwords do not match</Text>
              )}
          </FormControl>
          <Button
            mt="6"
            type="submit"
            disabled={Object.keys(errors).length > 0}
          >
            Update password
          </Button>
        </form>
      </Box>
    </Center>
  );
};

export default ChangePassword;
