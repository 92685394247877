import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  IconButton,
  Input,
  Text,
  Tooltip,
  useBoolean,
  VStack,
} from "@chakra-ui/react";
import { FC, ReactNode, useState } from "react";
import { FileUploadQuestion as FileUploadQuestionType } from "../../../types/FormConfig.types";
import { useAttachmentUploadMutation } from "../../services/attachments";
import { useSubmissionQuery } from "../../services/submissions";
import { OnNextQuestion, OnPreviousQuestion } from "../MainForm/MainForm";
import FileUploadDeleteButton from "./FileUploadDeleteButton";

const FileUploadQuestion: FC<{
  questionIndex: number;
  question: FileUploadQuestionType;
  submissionId: number;
  onNext: OnNextQuestion;
  onPrevious: OnPreviousQuestion;
  // attachments: SubmissionWithAnswers["attachments"] | null | undefined;
}> = (props) => {
  const {
    questionIndex,
    question,
    onNext,
    onPrevious,
    submissionId,
    // attachments,
  } = props;
  const [selectedFiles, setSelectedFiles] = useState<File[] | null>(null);
  const [uploading, setUploading] = useBoolean();

  const { data: submission, status } = useSubmissionQuery(submissionId);

  const uploadMutation = useAttachmentUploadMutation();

  return (
    <>
      {status === "success" &&
      submission &&
      submission.attachments.length > 0 ? (
        <Heading size="md">Uploaded:</Heading>
      ) : null}
      {status === "success" && submission ? (
        <Box>
          <VStack align="stretch" mb="8">
            {submission.attachments.map((a) => {
              return (
                <Flex
                  key={`uploaded-attachment-${a.id}`}
                  align="center"
                  justify="space-between"
                >
                  <Text mr="2">{a.name}</Text>
                  <FileUploadDeleteButton attachmentId={a.id} />
                </Flex>
              );
            })}
          </VStack>
        </Box>
      ) : null}
      {selectedFiles && selectedFiles.length > 0 ? (
        <Box mb="2">
          <Heading size="md">Selected files:</Heading>
        </Box>
      ) : null}
      {selectedFiles && selectedFiles.length > 0 ? (
        <Box>
          <VStack align="start" mb="6" alignItems="stretch">
            {selectedFiles.reduce((acc: ReactNode[], file, i) => {
              if (file) {
                acc.push(
                  <Flex key={i} justifyContent="space-between" align="center">
                    <Box>
                      {i + 1}: {file.name}
                    </Box>
                    <Tooltip label="Remove from selected">
                      <IconButton
                        ml="2"
                        aria-label="Remove attachment"
                        icon={<CloseIcon />}
                        onClick={() => {
                          setSelectedFiles((prev) => {
                            if (prev) {
                              return [
                                ...prev.slice(0, i),
                                ...prev.slice(i + 1),
                              ];
                            }
                            return prev;
                          });
                        }}
                      />
                    </Tooltip>
                  </Flex>
                );
              }
              return acc;
            }, [])}
          </VStack>
        </Box>
      ) : null}
      <HStack mb="2">
        <Button
          htmlFor="upload-files"
          as="label"
          cursor="pointer"
          variant="outline"
        >
          Select files
          <Input
            id="upload-files"
            hidden
            type="file"
            multiple
            onChange={(e) => {
              const files = e.target.files;

              if (files) {
                setSelectedFiles((prev) => {
                  if (prev) {
                    return [...prev, ...Array.from(files)];
                  }

                  return Array.from(files);
                });
              }
            }}
          />
        </Button>
        {selectedFiles === null || selectedFiles?.length === 0 ? null : (
          <Button
            isLoading={uploading}
            onClick={() => {
              if (selectedFiles && selectedFiles.length) {
                setUploading.on();
                const mutations = selectedFiles.map((file) => {
                  return uploadMutation.mutateAsync({ file, submissionId });
                });

                Promise.all(mutations)
                  .then((responses) => {
                    setUploading.off();

                    const { failedIndexes } = responses.reduce(
                      (
                        acc: {
                          successIndexes: number[];
                          failedIndexes: number[];
                        },
                        r,
                        i
                      ) => {
                        if (r && r.status === 201) {
                          acc.successIndexes.push(i);
                        } else {
                          acc.failedIndexes.push(i);
                        }

                        return acc;
                      },
                      { successIndexes: [], failedIndexes: [] }
                    );

                    setSelectedFiles((prev) => {
                      if (prev) {
                        const remaining = prev.filter((f, i) => {
                          return failedIndexes.includes(i);
                        });

                        return remaining;
                      }

                      return prev;
                    });
                  })
                  .catch((e) => {
                    console.error(e);
                    setUploading.off();
                  });
              }
            }}
          >
            Upload Selected Files
          </Button>
        )}
      </HStack>
      <Flex justifyContent="space-between" width="90%" maxW="50ch">
        {questionIndex === 0 ? null : (
          <Button
            mt="8"
            ml="5"
            mr="5"
            colorScheme="brandGray"
            onClick={() => {
              onPrevious(question);
            }}
          >
            Previous
          </Button>
        )}
        <Button
          mt="8"
          ml="5"
          mr="5"
          disabled={
            !!(
              status === "success" &&
              submission &&
              submission.attachments.length === 0
            )
          }
          onClick={() => {
            onNext(question, {
              question_number: question.questionNumber,
              question_response: "uploaded",
              submission_id: submissionId,
              completed: true,
            });
          }}
        >
          {question.finalQuestion ? "Finish" : "Next"}
        </Button>
      </Flex>
    </>
  );
};

export default FileUploadQuestion;
