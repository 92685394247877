import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Collapse,
  HStack,
  SimpleGrid,
  useDisclosure,
} from "@chakra-ui/react";
import { differenceInMonths } from "date-fns";
import { Props as DayzedProps, useDayzed } from "dayzed";
import { FC, useEffect, useState } from "react";
import Calendar from "./Calendar";
import DatepickerYears from "./DatepickerYears";

const monthNamesShort = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export type DatepickerProps = Omit<DayzedProps, "children" | "render">;

const Datepicker: FC<DatepickerProps> = (props) => {
  const [offset, setOffset] = useState(0);
  const [selectedYear, setSelectedYear] = useState<number | null>(null);
  const [selectedMonthIndex, setSelectedMonthIndex] = useState<number | null>(
    null
  );

  const {
    isOpen: isYearsOpen,
    onToggle: onYearsToggle,
    onOpen: onYearsOpen,
    onClose: onYearsClose,
  } = useDisclosure();
  const {
    isOpen: isMonthsOpen,
    onToggle: onMonthsToggle,
    onClose: onMonthsClose,
    onOpen: onMonthsOpen,
  } = useDisclosure();

  const dayzedData = useDayzed({ ...props, offset });

  useEffect(() => {
    onYearsOpen();
  }, [onYearsOpen]);

  useEffect(() => {
    if (selectedYear !== null && selectedMonthIndex !== null) {
      const now = new Date();
      const theFirst = new Date(now.getFullYear(), now.getMonth(), 1);

      const offsetFromCurrentMonth = differenceInMonths(
        new Date(selectedYear, selectedMonthIndex, 1),
        theFirst
      );

      setOffset(offsetFromCurrentMonth);
    }
  }, [selectedMonthIndex, selectedYear]);

  return (
    <Box>
      <HStack justify="center" spacing="5">
        <Button
          size="sm"
          variant="outline"
          rightIcon={isYearsOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          onClick={() => {
            onMonthsClose();
            onYearsToggle();
          }}
        >
          {selectedYear ? selectedYear : "Choose year"}
        </Button>
        <Button
          size="sm"
          variant="outline"
          rightIcon={isMonthsOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          onClick={() => {
            onYearsClose();
            onMonthsToggle();
          }}
        >
          {selectedMonthIndex !== null
            ? monthNamesShort[selectedMonthIndex]
            : "Choose month"}
        </Button>
      </HStack>
      <Collapse in={isYearsOpen}>
        <DatepickerYears
          selectedYear={selectedYear ? selectedYear : new Date().getFullYear()}
          onSelect={(year) => {
            setSelectedYear(year);
            onYearsClose();
            onMonthsOpen();
          }}
        />
      </Collapse>
      <Collapse in={isMonthsOpen}>
        <SimpleGrid
          columns={3}
          spacing="2"
          mt="2"
          maxW="350px"
          ml="auto"
          mr="auto"
        >
          {monthNamesShort.map((month, index) => {
            return (
              <Button
                key={index}
                size="sm"
                variant={selectedMonthIndex === index ? "solid" : "outline"}
                onClick={() => {
                  setSelectedMonthIndex(index);
                  onMonthsClose();
                }}
              >
                {month}
              </Button>
            );
          })}
        </SimpleGrid>
      </Collapse>
      <Collapse in={!isMonthsOpen && !isYearsOpen}>
        {/* <Text mt="4" mb="3" fontSize="lg" textAlign="center">
          {monthNamesShort[calendars[0].month]} {calendars[0].year}
        </Text> */}
        <Box mt="4" mb="3">
          <Calendar {...dayzedData} />
        </Box>
      </Collapse>
    </Box>
  );
};

export default Datepicker;
