import { Box, Button, HStack, SimpleGrid } from "@chakra-ui/react";
import { FC, useState } from "react";

const DatepickerYears: FC<{
  selectedYear: number;
  onSelect: (year: number) => void;
}> = (props) => {
  const [page, setPage] = useState(0);

  const currentDecade = Math.floor(new Date().getFullYear() / 10) * 10;
  const decadeToShow = currentDecade + page * 10;
  const years: number[] = [];
  for (let i = 0; i < 10; i++) {
    years.push(decadeToShow + i);
  }

  return (
    <Box mt="2" mb="3">
      <HStack mb="2" justifyContent="space-between">
        <Button
          variant="outline"
          onClick={() => {
            setPage((p) => p - 1);
          }}
        >
          {decadeToShow - 10}s
        </Button>
        <SimpleGrid columns={5} spacing="2" placeItems="center">
          {years.map((year) => {
            return (
              <Button
                key={`year-button-${year}`}
                variant={props.selectedYear === year ? "solid" : "ghost"}
                onClick={() => {
                  props.onSelect(year);
                }}
              >
                {year}
              </Button>
            );
          })}
        </SimpleGrid>
        <Button
          variant="outline"
          onClick={() => {
            setPage((p) => p + 1);
          }}
        >
          {decadeToShow + 10}s
        </Button>
      </HStack>
    </Box>
  );
};

export default DatepickerYears;
